// export default [
//   {
//     pText1: "The students here can learn about the best ways of instructing a yoga student which can enable them to become skilled yoga teachers in their own right.",
//     pText2: "Atharv Yogshalas research-based Yoga Intensive 10 days retreat serves as the perfect yoga introduction for beginners as well as the perfect yoga immersion for those who have been practicing yoga for some time. This yoga intensive 10 days retreat at an atharvyogshala yoga retreat in Rishikesh, India introduces yoga in a profound and accessible way. This holistic and spiritual yoga program also offers more experienced students an immersion designed to deepen their practice and understanding of yoga.",
//     pText3:"Participants in this 10 days yoga retreat will learn a wide variety of classical Hatha Yoga practices including yoga asana (yoga postures), pranayama (breath control), mudras (psychic gestures), meditation, and deep relaxation techniques. Traditional yogic detox practices will also be introduced including Jala neti (nasal passage cleansing), vaman & dhauti (stomach & esophagus cleansing), laghooshankhaprakshalana (intestinal cleansing), and trataka (gazing at a candle flame).",
//     pText4: "Within a traditional atmosphere, atharvyogshala, Yoga Retreat teaches yoga using the age-old methods of the gurukula (traditional Indian teaching method) in an environment free from dogma. Yoga philosophy is an integral part of any yoga retreats at atharvyogshala Yoga Retreat. Fundamental to learning about yoga is an understanding of the ‘why’ behind any yogic practice. It is also crucial to understand that there is so much more to yoga than just the practice of asana (physical postures).",
    
//     ulList1: { a:"Hatha Yoga",
//       b: "Spiritual Movies / Lectures",
//       c: "Initiation into Mantrosparsh – An ancient yogic practice",
//     },

//     ulList2: { a:"Yogic Philosophy",
//       b: "Yagnas – The Fire Ceremony",
//       c: "Breathing Exercises and Pranayama",
//     },

//     ulList3: { a:"Nada Yoga",
//       b: "Spiritual Kirtans",
//       c: "Mantra Chanting Healing",
//     },
//   },
// ]


export default [
  { 
    id: 1,
    h4Text: "Benefits",
    h5Text: "",
    pText: [],
    listItems: ["This retreat is the perfect introduction to yoga philosophy, asana, pranayama, yogic kriyas, andmeditation.",
    "This retreat is ideal for yogis wishing to deepen their practice and learn more about yoga philosophy. For Yoga Teachers",
    "This retreat offers an in-depth understanding of yoga philosophy, yoga as therapy & the essence of what is meant by yoga.",],
  
  },


  { 
    id: 1,
    h4Text: "About the program",
    h5Text: "",
    pText: ["The students here can learn about the best ways of instructing a yoga student which can enable them to become skilled yoga teachers in their own right.",
      "Atharv Yogshalas research-based Yoga Intensive 10 days retreat serves as the perfect yoga introduction for beginners as well as the perfect yoga immersion for those who have been practicing yoga for some time. This yoga intensive 10 days retreat at an atharvyogshala yoga retreat in Rishikesh, India introduces yoga in a profound and accessible way. This holistic and spiritual yoga program also offers more experienced students an immersion designed to deepen their practice and understanding of yoga.",
      "Participants in this 10 days yoga retreat will learn a wide variety of classical Hatha Yoga practices including yoga asana (yoga postures), pranayama (breath control), mudras (psychic gestures), meditation, and deep relaxation techniques. Traditional yogic detox practices will also be introduced including Jala neti (nasal passage cleansing), vaman & dhauti (stomach & esophagus cleansing), laghooshankhaprakshalana (intestinal cleansing), and trataka (gazing at a candle flame).",
      "Within a traditional atmosphere, atharvyogshala, Yoga Retreat teaches yoga using the age-old methods of the gurukula (traditional Indian teaching method) in an environment free from dogma. Yoga philosophy is an integral part of any yoga retreats at atharvyogshala Yoga Retreat. Fundamental to learning about yoga is an understanding of the ‘why’ behind any yogic practice. It is also crucial to understand that there is so much more to yoga than just the practice of asana (physical postures).",
    ],
    listItems: [],
  
  },

    { 
    id: 2,
    h4Text: "Kriya Yoga",
    h5Text: "",
    pText: ["Kriya Yoga, Shat Karma, or Shat Kriya as they are often known, are six yogic cleansing processes used by yoga practitioners to help purify the physical body. During the retreats, you will be explained some of these methods to you and you can apply these methods during and after your stay.",
    "The body has an incredible natural tendency towards homeostasis. That means our body has a natural tendency to maintain stability in the circumstances given to us. The Shat Kriyas come to our aid to help us remove built-up toxins from our body, with particular emphasis on the respiratory and digestive systems.",
    ],
    
    listItems: [],
  
  },

  { 
    id: 3,
    h4Text: "Pranayama",
    h5Text: "",
    pText: ["Pranayama is the control, regulation, and letting go of the breath. There are many different pranayama methods with different effects on the body. Practicing pranayama gives the practitioner more awareness over the breath, the breathing pattern, and the respiratory system.",
    "The easiest way to start with pranayama is to observe your breathing pattern. Sit in a comfortable position or lie down in Shavasana. Close your eyes and observe your breathing and try not to interfere. Focus on the rhythm of your breathing, feel the breath flowing in and out of your nose.",
    ],
    
    listItems: [],
  
  },

  { 
    id: 4,
    h4Text: "Meditation",
    h5Text: "",
    pText: ["Although most people meditate with closed eyes, many beginners find it useful to have a point of focus, such as a candle. Concentrating on the flame can make it easier to clear your mind.",
    "When learning how to meditate, beginners tend to get frustrated by the persistence of outside thoughts — all the anxieties, to-do lists, and random memories that parade constantly through the brain. Instead of fighting them off, simply observe them as they enter your mind and let them pass. Repeating a mantra to yourself is another good way to maintain your focus.",
    "During the retreat you will be taught different meditation techniques. After the retreat, you will have the knowledge to meditate by yourself and find the calmness you felt during the retreat.",
    ],
    
    listItems: [],
  
  },

  { 
    id: 4,
    h4Text: "Asana",
    h5Text: "",
    pText: ["Asana is what most western people know as yoga. They are not also a great way to move your body, become more flexible, attain better posture or even get a great workout. Asanas are postures or poses which open energy channels and psychic channels. Asanas are not simply a physical practice, they are very much spiritual practice.",
    "During your retreat you will be taught different styles of yoga. The organizer has a wide variety of knowledge about different yoga styles and different backgrounds. This makes it possible for them to provide you with different types of asana classes. ",
    "Hatha Vinyasa is a flowing form of yoga. Every asana is connected with the breath and with every breath you will flow from one asana to the other. This requires focus, attention, and control. Yin yoga is a calmer form of yoga. It consists of passive poses, which you stay in for a longer time. It is a great way to open the joints and loosen the connective tissue.",
    ],
    
    listItems: [],
  
  },



]