import React from "react";
import H2Comp from "../H2Comp.js"
import TransportTypeComp from "../retreat_components/TransportTypeComp.js"
import location_icon from "../../assets/images/retreat_page_images/location-icon.svg";
import location_img from "../../assets/images/retreat_page_images/location-img.svg";

import { courseLocation } from "../../data/YTT_RYA_data.js"



export default function CourseLocation(){
  
  const abtLocationText = courseLocation.map((item, idx)=>{
    return <p className="address-decription">{item.pText}</p>
  })

  return (<div className="content-card">
    <H2Comp
        h2Text="Location"
      />
    <div className="adress-box margin-32">
          <div>
              <p className="address-text">Rishikesh Yoga Association, Balak Nath Temple Street , Upper Tapovan, Tapovan, Rishikesh, Uttarakhand 249192, India</p>
              {abtLocationText}
          </div>
          <div className="location-visual-box">
              <img className="location-img" src={location_img}/>
              <button className="view-map-btn">View Map</button>
          </div>
      </div>
      <div className="transport-box">
        <h4 className="retreat--h4">How to Reach</h4>
        <TransportTypeComp 
          img="by-air-icon.svg"
          transportType="By Air"
          label1="Nearest Airport"
          value1="DED - Jolly Grant Airport"
          distance1="24 Kms"
          label2="International Airport"
          value2="DEL - Indira Gandhi International Airport"
          distance2="260 Kms"
        />

        <TransportTypeComp 
          img="by-bus-icon.svg"
          transportType="By Bus"
          label1="Nearest Bus Station"
          value1="Rishikesh Bus Stand"
          distance1="15 Kms"
          // label2="International Airport"
          // value2="DEL - Indira Gandhi International Airport"
          // distance2="260 Kms"
        />

        <TransportTypeComp 
          img="by-train-icon.svg"
          transportType="By Train"
          label1="Nearest Railway Station"
          value1="New Rishikesh"
          distance1="17 Kms"
          // label2="International Airport"
          // value2="DEL - Indira Gandhi International Airport"
          // distance2="260 Kms"
        />
      </div>
  </div>)
}
