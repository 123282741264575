import React from "react"

// Import images

import logo from "../assets/images/home_page_images/logo.svg";
import logoWhite from "../assets/images/home_page_images/yogsidhi_logo_white.svg"
import whats_app_btn from "../assets/images/home_page_images/whatsapp_icon.svg";
import MobileNav from "./MobileNav";
// import user_icon from "../assets/images/home-page-images/noun_User_2328155"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faL, faXmark } from '@fortawesome/free-solid-svg-icons'
import { AnimatePresence, motion, useMotionValueEvent, useScroll } from "framer-motion"
import { isMobile } from "react-device-detect";
import useToggle from "./genric_components/useToggle";
import { Link, useNavigate } from "react-router-dom";




export default function TopNavigation(){

  const [isMobile, setIsMobile] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [width, setWidth] = React.useState(window.innerWidth)
  const [hidden, setHidden] = React.useState(false)

  const [on, toggle] = useToggle()

  const { scrollY } = useScroll()


  const pageLinks = {
    link: "/"
  }


  React.useEffect(()=>{
    if(width < "600"){
        setIsMobile(true)        
    }
  },[])

  

  

  function handleClick(e){
    e.preventDefault()  
    setIsOpen(prevOpen => !prevOpen)
  }

  React.useEffect(()=>{
      if(isOpen ){document.body.style.overflow = 'hidden'}
      else{
          document.body.style.overflow = 'unset';
      }
  },[isOpen])

  // React.useEffect(()=>{
  //   const unsub = scrollY.on("change", (latest)=> console.log(latest))
  //   return () => unsub()
  // },[scrollY])

  useMotionValueEvent(scrollY, "change", (latest)=> {
    const previous = scrollY.getPrevious()
    if(latest > previous && latest > 50){
      setHidden(true)
    } else {
      setHidden(false)
    }
    
  } )


// Animation Code for framer motion
  const menuVars = {
    initial: {
      scaleY: 0,
    },
    animate: {
      scaleY: 1,
      transition: { 
        duration: .3,
        ease: [.12,0, .39, 0]}
    },
    exit: {
      scaleY: 0,
      transition: {
        delay: 0.3, 
        duration: .3,
        ease: [.22, 1, .36, 1],
      },
    },
  }

  const containerVars = {
    initial: {
      transition: {
        staggerChildren: 0.9,
      },
    },

    open: {
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.7,
      },
    },

  }

  const logoVars = {
    start: {
      y: "-100px",
    },
    animate: {
      y: "0px",
      transition: {
      delay: 0,
      duration :.5,
      ease: [.12,0, .39, 0]},
    },
    exit: {
      y: 0,
      y: "-80px",
      transition: {
      delay: .1,
      duration: .5,
      ease: [.22, 1, .36, 1]}
    }

  }

  return (
      <>
        <motion.nav
          variants= {{
            visible: { y: 0 },
            hidden: { y: "-100%" }
          }}
          animate= { hidden ? "hidden" : "visible"}
          transition={{duration: 0.35, ease: "easeInOut"}}
          className="mobile-nav-bar"
        > 
          <a href="/">
            <img style={{width: "6rem"}}src={logoWhite} />
          </a>
          <div className="mobile-nav-closed">
            <FontAwesomeIcon 
                    icon={faBars} 
                    style={{color: "#ffffff", width: "2rem"}}
                    onClick={handleClick} 
            />
          </div> 
        </motion.nav>

        <AnimatePresence>
          {!isMobile && !isOpen || isOpen ? <motion.div 
            variants={menuVars}
            initial={isMobile && "initial"}
            animate={isMobile && "animate"}
            exit={isMobile && "exit"} 
            
            className={`top-navigation-bar origin-top`}
          >   

            {isMobile && isOpen && <motion.div
              initial={{y: "-20px", opacity: 0}} 
              animate={{y: "0px", opacity: 1, transition:{delay: 0, duration :.5, ease: [.12,0, .39, 0]} }}
              className="close-icon">
              <FontAwesomeIcon 
                icon={faXmark} 
                style={{color: "#000000",width: "2rem"}} 
                onClick={handleClick}
              />
            </motion.div>}

            <motion.div 
              variants = {logoVars} 
              initial={isMobile && "start"} 
              animate={isMobile && "animate"} 
              exit={isMobile && "exit"}
              className="logo-box" href="/"
            >
              <a href="/"><img  className="logo" src={logo}/> </a>
            </motion.div>
            
            <motion.div 
              variants={containerVars}
              initial={isMobile && "initial"}
              animate={isMobile && "open"}
              exit={isMobile && "initial"}
            >
              <div className="overflow-hidden">
                <MobileNavLinks 
              />
              </div>
            </motion.div> 
          </motion.div> : null}
        </AnimatePresence>
      </>
  )
}

const menuLinkVars = {
  initial: {
    y: "30vh",
    transition: {
      duration: .5,
    }
  },
  open: {
    y: 0,
    transition: {
      duration: .5,
    }
  },
}

function MobileNavLinks({}){
  return (
    <motion.div 
      variants={menuLinkVars}
    >
      <nav>
          <div className="nav-group">
            <a href="/course">Course</a>
            <a href="/retreat">Retreat</a>
            <a href="/panchkarma">Panchkarma</a>
            <a href="/about">About Us</a>
          </div>

          <div className="nav-group">
            <a aria-label="Contact Us" 
              href="https://wa.me/9650132671?text=hi" 
              className="whatsapp-button"
            > 
              <img  
                alt="Chat on WhatsApp" 
                src={whats_app_btn} 
              />
                Send Message
            </a>
      
            {/*<a class="cart" href=""><img src={cart_icon}/>Cart</a>*/}
            {/* <a class="login" href="#"><img src=""/>Login</a> */}
          </div>
      </nav>
    </motion.div>
  )
}



// if (!isOpen && isMobile){
//   return (
//       <div
        // variants={menuVars}
        // initial="initial"
        // animate="animate"
        // exit="exit" 
//         className="mobile-nav-bar"
//       >  
//         <img src={logoWhite} />
//         <div className="mobile-nav-closed">
//           <FontAwesomeIcon 
//               icon={faBars} 
//               style={{color: "#ffffff",}}
//               onClick={handleClick} 
//           />
//         </div> 
//       </div>
//   )
// } else if (isOpen && isMobile){
//   return (
//     <AnimatePresence>
//       <motion.div 
//         variants={menuVars}
//         initial="initial"
//         animate="animate"
//         exit="exit"
//         className="top-navigation-bar origin-top"
//       >
//           {isMobile && <motion.div 
//             className="close-icon"
//             variants={menuVars}
//             exit="exit"
//           >
//               <FontAwesomeIcon 
//                   icon={faXmark} 
//                   style={{color: "#000000",}} 
//                   onClick={handleClick}
//               />
//           </motion.div>}
//         {/*// Logo*/}
        
//         <a className="logo-box" href="/">
//             <img  className="logo" src={logo}/>
//         </a>
        
//         {/*<!-- Primary Nav  -->*/}
        
//         <nav>
//             <div className="nav-group">
//               <a href="/course">Courses</a>
//               <a href="/retreat">Retreats</a>
//               {/*<a href="#">Panchkarma</a>*/}
//               <a href="/about">About Us</a>
//             </div>

//             <div className="nav-group">
//               <a aria-label="Contact Us" href="https://wa.me/9650132671?text=hi" className="whatsapp-button"> <img  alt="Chat on WhatsApp" src={whats_app_btn} />Send Message</a>
        
//               {/*<a class="cart" href=""><img src={cart_icon}/>Cart</a>*/}
//               {/* <a class="login" href="#"><img src=""/>Login</a> */}
//             </div>
//         </nav>
//       </motion.div>
//     </AnimatePresence>
//   )
// } else if (!isMobile){
//    return(
//    <div 
//       className="top-navigation-bar"
//     >
//       {/*// Logo*/}
      
//       <a className="logo-box" href="/">
//           <img  className="logo" src={logo}/>
//       </a>
      
//       {/*<!-- Primary Nav  -->*/}
      
//       <nav>
//           <div className="nav-group">
//             <a href="/course">Courses</a>
//             <a href="/retreat">Retreats</a>
//             {/*<a href="#">Panchkarma</a>*/}
//             <a href="/about">About Us</a>
//           </div>

//           <div className="nav-group">
//             <a aria-label="Contact Us" href="https://wa.me/9650132671?text=hi" className="whatsapp-button"> <img  alt="Chat on WhatsApp" src={whats_app_btn} />Send Message</a>
      
//             {/*<a class="cart" href=""><img src={cart_icon}/>Cart</a>*/}
//             {/* <a class="login" href="#"><img src=""/>Login</a> */}
//           </div>
//       </nav>
//     </div>
//   )
// }







