import React from "react"
import H2Comp from "../H2Comp.js";
import incluexclu_data from "../../data/course_data/incluexclu_data.js";
import ContentItems from "../ContentItems.js";
import H4Comp from "../H4Comp.js";
import ParaComp from "../ParaComp.js";
import ULComp from "../ULComp.js";



export default function CourseIncluExclu(){

  const incluexclu_content = incluexclu_data.map(item => {
    return (<ContentItems 
        h4Text={item.h4Text}
        h5Text={item.h5Text}
        pText={item.pText}
        listItems={item.listItems}
    />)
  })

  return (<div className="content-card">
    <H2Comp
        h2Text="Inclusions & Exclusions"
      />
      {incluexclu_content}

  </div>)

}