

export const courseImgGalleryData = [ 
  {
    id: 1,
    galleryImg: ["rya1.png", "rya2.png", "rya3.png", "rya1.png", "rya2.png"]
  },
]


export const courseOverviewData = [
  { 
    id: 1,
    h4Text: "Ashtanga Yoga Teacher Training in Rishikesh, India: Enroll Now!",
    pText: ["Embark on a life-changing journey with our 200-hour Ashtanga yoga teacher training course in the serene surroundings of Rishikesh. Dive deep into the practice of Ashtanga yoga and unlock your full potential under the guidance of experienced teachers who bring years of wisdom and expertise."],
    listItems: [],
  
  },

  { 
    id: 2,
    h4Text: "Highlights",
    pText: [],
    listItems: ["Daily Yoga Asana Classes",
    "Meditation and Mantra Chanting Sessions",
    "Comprehensive Course Books and Manuals Included",
    "Study Yoga Philosophy, Anatomy, and Physiology",
    "Enjoy Daily Breakfast, Lunch, and Dinner",
    "25 Nights of Accommodation Included",
    "Excursion to the Himalayas Included"],
  
  },

  { 
    id: 3,
    h4Text: "Skill level",
    pText: [],
    listItems: ["Beginner","Intermediate","Advanced"],
  
  },

  { 
    id: 4,
    h4Text: "Yoga styles",
    pText: [],
    listItems: ["Hatha","Vinyasa","Ashtanga"],
  
  },
]

export const courseProgramData = [
  { 
    id: 1,
    h4Text: "About Ashtanga yoga",
    h5Text: "",
    pText: ["Discover the ancient practice of Ashtanga yoga, derived from the Sanskrit term meaning 'eight-limbs' of yoga. These eight limbs, outlined by Sage Patanjali in the Yoga Sutra, encompass principles like yama, niyama, asana, pranayama, pratyahara, dharana, dhyana, and samadhi. Ashtanga yoga gained modern popularity through K. Pattabhi Jois, a student of Krishnamacharya, who developed specific posture sequences within this dynamic, breath-linked practice."],
    listItems: [],
  
  },

  { 
    id: 2,
    h4Text: "",
    h5Text: "",
    pText: [
      "Pattabhi Jois structured Ashtanga into three main series: the primary series (yoga Chikitsa), the intermediate series (Nadi Shodhana), and the advanced series (Sthira Bhaga), each focusing on different aspects of physical and mental development. Our 200-hour yoga teacher training in Rishikesh, conducted by Rishikesh Yoga Association, emphasizes the primary series, ideal for beginners to grasp the fundamentals deeply."
    ],

    listItems: [],
  
  },
  
  { 
    id: 3,
    h5Text: "",
    h4Text: "",
    pText: ["During this training, you'll delve into various practices such as shatkarma (cleansing techniques) including Jal Neti and Sutra Neti, eye cleansing methods like Trataka and eye cups, Vaman Dhauti, Kapalbhati, and Agnisara. These techniques enhance both physical and mental well-being, laying a strong foundation for your yoga journey."],
    listItems: [],
  
  },

  { 
    id: 4,
    h4Text:"Ashtanga Yoga Teacher Training: 200-Hour Course Syllabus",
    h5Text: "Cleansing Techniques (Shatkarma)",
    pText: [],
    listItems: [
      "Introduction to Cleansing Methods",
      "Nasal Cleansing: Jal Neti and Sutra Neti",
      "Eye Care Practices: Trataka and Eye Cups",
      "Digestive Cleansing Technique (Vaman Dhauti)",
      "Breathing Exercise (Kapalbhati)",
      "Strengthening the Digestive System (Agnisara)"
    ],
  
  },

  {
    id:5,
    h4Text:"",
    pText:[],
    h5Text: "Ashtanga Yoga Poses and Adjustments",
    listItems: [
      "Sun Salutation A (Surya Namaskar A)",
      "Sun Salutation B (Surya Namaskar B)",
      "Toe to Hand Pose (Padangusthasana)",
      "Hand to Foot Pose (Padahasthasana)",
      "Extended Triangle Pose (Utthita Trikonasana)",
      "Revolved Triangle Pose (Parivrtta Trikonasana)",
      "Extended Side Angle Pose (Utthita Parsvakonasana)",
      "Wide-Legged Forward Bend (Prasarita Padottanasana A, B, C, D)",
      "Intense Side Stretch Pose (Parsvottanasana)",
      "One-Legged Toe to Hand Pose (Eka Pada Padangusthasana A, B, C, D)",
      "Half Bound Lotus Intense Stretch (Ardha Baddha Padmottanasana)",
      "Chair Pose (Utkatasana)",
      "Warrior Pose A and B (Virabhadrasana A and B)",
      "Staff Pose (Dandasana)",
      "Seated Forward Bend (Paschimottanasana A, B, C, D)",
      "Intense East Stretch Pose (Purvattanasana)",
      "Half Bound Lotus Forward Bend (Ardha Baddha Padma Uttanasana)",
      "Three-Limbed Forward Bend (Triang Mukaikapada Pashimottanasana)",
      "Head to Knee Pose (Janu Sirsasana A, B, C)",
      "Seated Spinal Twist (Marichyasana A, B, C, D)",
      "Boat Pose (Navasana)",
      "Shoulder-Pressing Pose (Bhujapidasana)",
      "Tortoise Pose (Kurmasana)",
      "Reclining Tortoise Pose (Supta Kurmasana)",
      "Embryo in the Womb Pose (Garbha Pindasana)",
      "Rooster Pose (Kukkutasana)",
      "Bound Angle Pose A and B (Baddha Konasana A and B)",
      "Angle Pose (Konasana)",
      "Seated Wide Angle Pose (Upavishta Konasana)",
      "Reclining Wide Angle Pose (Supta Konasana)",
      "Reclining Big Toe Pose A and B (Supta Padangusthasana A and B)",
      "Both Feet to Hand Pose (Ubhaya Padanghusthasana)",
      "Upward Facing Intense Stretch (Urdhva Mukha Pashimottanasana)",
      "Bridge Pose (Setu Bandhasana)",
      "Upward Bow Pose (Urdhva Dhanurasana)",
      "Supported Shoulderstand (Salamba Sarvangasana)",
      "Plow Pose (Halasana)",
      "Ear Pressure Pose (Karnapidasana)",
      "Upward Abdomen Pose (Urdhva Pindasana)",
      "Fish Pose (Matsyasana)",
      "Upward Lotus Pose (Urdhva Padmasana)",
      "Headstand (Sirsasana)",
      "Upward Staff Pose (Urdhva Dandasana)",
      "Bound Lotus Pose (Baddha Padmasana)",
      "Yogic Seal Pose (Yogimudra)",
      "Upside Down Pose (Uplutih)",
      "Corpse Pose (Savasana)",
    ]
  },

  {
    id:6,
    h4Text:"",
    pText:[],
    h5Text: "Hatha Yoga Poses and Adjustments",
    listItems: ["Dynamic Postures: Experience fluid movements with the Pawanmuktasana series, Marjala asana, Vyaghrasana, and Surya Namaskar.",
    "Standing Postures: Find stability and alignment with Tadasana, Utkatasana, Padhastasana, Trikonasana, and more.",
    "Inverted Postures: Discover new perspectives with Sirsasana, Sarvangasana, Halasana, and Pinchamayurasana.",
    "Twisting Postures: Stimulate the spine and internal organs with Ardhmatsyendra asana, Parivrittijanusirsh asana, and Kati Chakrasana.",
    "Back Bend Postures: Open the heart and expand the chest with Bhujangasana, Shalabhasana, Dhanurasana, and more.",
    "Forward Bend Postures: Cultivate introspection and release tension with Paschimotanasana, Janusirasana, and Adho Mukha Svanasana.",
    "Balancing Postures: Enhance focus and coordination with Vrkshasana, Bak Dhayan asana, Mayurasana, and others.",
    "Relaxation Postures: Unwind and rejuvenate with Shashankasana, Makarasana, and Savasana.",
    ]
  },

  {
    id:7,
    h4Text:"",
    pText:[],
    h5Text: "Vinyasa Flow",
    listItems: [
      "Standing Yoga Poses: Strengthen and ground yourself with on-your-feet Yogasanas.",
      "Seated Flow: Find balance and flexibility with seated Vinyasa Sequences.",
      "Seated Stretching: Release tension and improve mobility with seated posterior stretch sequences.",
      "Balancing Acts: Enhance stability and focus with on-one-leg Yogasanas.",
      "Relaxing Supine Postures: Unwind and rejuvenate with the supine sequence.",
      "Heart-Opening Bow Pose: Experience openness and vitality with the bow pose sequence.",
      "Triangular Alignment: Improve alignment and strength with the triangle pose sequence.",
      "Inversion Journey: Explore new perspectives and energy flow with the inverted posture sequence.",
      "Mindful Meditation: Cultivate inner peace and clarity with the meditative pose sequence.",
      "Dynamic Flow: Flow gracefully through sequences with Viseha Vinyasa Karma.",
    ]
  },


  {
    id:8,
    h4Text:"",
    pText:[],
    h5Text: "Pranayama",
    listItems: [
      "Introduction to Pranayama: Learn the basics of this ancient breathing practice.",
      "Understanding the Importance: Discover why Pranayama is essential for holistic well-being.",
      "Yogic Breathing Techniques: Enhance awareness through specialized breathing methods.",
      "Benefits of Pranayama: Explore the numerous advantages this practice offers for mind and body.",
      "Variety of Pranayama Practices: Dive into different Pranayama techniques and their general guidelines",
      "Nadi Sodhana Pranayama",
      "Bhramari Pranayama",
      "Sheetali and Sheetkari",
      "Ujjayi Pranayama",
    ],
  },

  {
    id:9,
    h4Text:"",
    pText:[],
    h5Text: "Mudras",
    listItems: [
      "Introduction to Yogic Mudra: Explore the ancient art of hand gestures for holistic well-being.",
      "Jnana and Chin Mudra: Delve into these symbolic gestures for wisdom and concentration.",
      "Yoni Mudra: Experience deep relaxation and inner harmony with this calming mudra.",
      "Bhairava Mudra: Tap into your inner strength and confidence with this powerful gesture.",
      "Shambhavi Mudra: Enhance focus and intuition with this eye-opening technique.",
      "Nasikagra Mudra: Stimulate mental clarity and alertness with this nose-tip gesture.",
      "Prana Mudra: Boost vitality and energy flow with this rejuvenating mudra.",
      "Maha Mudra: Harmonize mind, body, and spirit with this profound gesture.",
      "Maha Vedha Mudra: Unlock inner wisdom and insight through this transformative mudra.",
      "Mudras for Better Health: Explore various mudras tailored for enhancing overall well-being.",
      "Mudra for Concentration: Improve focus and mental clarity with specialized hand gestures.",
      "Common Mudras for Daily Use: Incorporate these simple yet effective mudras into your daily routine for balance and vitality.",
     ],
  },

  {
    id:10,
    h4Text:"",
    pText:[],
    h5Text: "Bandhas",
    listItems: ["Introduction of Bandha",
      "Uddiyana bandha",
      "Jalandhar bandha",
      "Mula bandha",
      "Maha bandha",
     ],
  },

  {
    id:11,
    h4Text:"",
    pText:[],
    h5Text: "Yoga philosophy",
    listItems: ["Introduction to yoga",
      "Its philosophy and evolution",
      "Concept of Hatha yoga",
      "Different practices of Hatha yoga",
      "Introduction to yoga sutras of patanjali",
      "Importance of discipline in path of yoga",
      "Definition of yoga",
      "Vrittis (mental fluctuations) and their classification",
      "Consistent practice and non-attachment (Abhayasa and Vairagya)",
      "Obstacles and distractions on the path of yoga (concepts of citta-viksepa and antaraya)",
      "Ashtanga yoga -Yama, Niyama",
      "Asana, pranayama, pratyahara, dharana, dhyan, samadhi",
      "Sattva, rajas, and tamas (Triguna)",
      "Introduction to chakras and their functions",
      "Lives of yogis (Inspiring stories)",
    ],
  },


  {
    id:12,
    h4Text:"",
    pText:[],
    h5Text: "Meditation",
    listItems: ["Introduction to meditation and concentration",
      "Breathing awareness meditation",
      "Tips for developing concentration",
      "Bahir and Antar trataka",
      "Dynamic meditation (osho meditation)",
      "Nada meditation (sound meditation)",
      "Ajapajapa",
      "Antar Mouna (silence practice)",
      "Chakra meditation",
      "Third eye meditation",
      "Mantra meditation",
      "Yantra meditation",
      "61 point meditation",
      "Yoga Nidra inner visualization,"
    ],
  },

  {
    id:13,
    h4Text:"",
    pText:[],
    h5Text: "Mantra chanting",
    listItems: ["What is the mantra and benefits of chanting mantra?",
      "Shanti mantra (mantra of peace)",
      "Guru mantra (mantra for guru)",
      "Ganesh mantra (mantra for auspiciousness)",
      "Mahamritumjaya mantra (mantra on lord shiva)",
      "Gayatri mantra (universal prayer)",
      "Yogena Cittasya (Sloka on sage Patanjali)",
      "Hare rama, Hare Krishna (Maha mantra)",
    ],
  },

  {
    id:14,
    h4Text:"",
    pText:[],
    h5Text: "Anatomy",
    listItems: ["Skeletal system",
      "Muscular system",
      "Muscle functions according to joint movements",
      "Respiratory system",
      "Endocrine system",
      "Circulatory system",
      "Nervous system",
      "Digestive system",
      "Chakras inversions",
      "Physiological benefits of asana",
    ],
  },

  {
    id:15,
    h4Text:"",
    pText:[],
    h5Text: "Teaching methodology",
    listItems: ["Positive and conscious communication",
      "Friendship and trust",
      "Time management",
      "Qualities of a teacher",
      "Principles of demonstrating, observation, assisting, correcting",
      "Use of voice in class",
      "Mental and emotional preparation for teaching",
      "Class preparation",
      "Step-by-step class structure planning",
    ],
  },

  {
    id:16,
    h4Text:"",
    pText:[],
    h5Text: "Evaluation is based on",
    listItems: ["Written test",
      "Asana practical test",
      "Attendance",
      "Performance",
      "Behavior",
    ],
  },

  {
    id:17,
    h4Text:"",
    pText:["Attendance is crucial for all students, as each class contributes to their progress. If a student faces illness or emergency situations, they can request leave with permission from the respective teacher. However, leaves must be justified, as unexplained absences can impact the student's monthly performance significantly."],
    h5Text: "",
    listItems: [],
  },
]


export const courseScheduleData = [
  { id: 1,
    time: "06:30 - 07:30",
    activity: "Pranayama",},
    
  { id: 2,
    time: "07:45 - 09:15",
    activity: "Hatha Yoga",},
    

  { id: 3,
      time: "09:15 - 10:00",
      activity: "Breakfast",},

  {
    id: 4,
    time: "10:00 - 11:00",
    activity: "Yoga Anatomy",
  },

  {
    id: 5,
    time: "11:30 - 12:30",
    activity: "Yoga Philosophy",
  },
  
  {
    id: 6,
    time: "13:00 - 14:00",
    activity: "Lunch",
  },
  
  {
    id: 7,
    time: "17:15 - 17:45",
    activity: "Ashtanga Vinyasa",
  },
  
  {
    id: 8,
    time: "18:00 - 19:00",
    activity: "Meditation & Mantra Chanting",
  },
  
  {
    id: 9,
    time: "19:15 - 20:00",
    activity: "Dinner",
  },
  
  {
    id: 10,
    time: "22:00",
    activity: "Lights Off",
  },
]

export const courseAccomodationData = [
  { 
    id: 1,
    h4Text: "",
    pText: [
      "Students will be staying at Rishikesh Yoga Association during the course period. The school is privileged with a location that is close to the famous Laxman Jhula bridge and the local market of Rishikesh.",
      "Accommodation options include twin shared rooms and private rooms, both spacious and well-lit with outward-facing views. Twin shared rooms feature two separate beds, while private rooms offer a large single bed.",
      "All rooms boast modern amenities, including an attached bathroom with a western toilet and hot shower, a balcony overlooking the mountains, and a private entrance. Additionally, students are provided with essential linens, towels, toiletries, blankets, and high-speed Wi-Fi throughout their stay."
    ],
    listItems: [],
  
  },
]

export const courseFoodData = [
  { 
    id: 1,
    h4Text: "",
    pText: [
      "Yoga fosters positive changes in both body and mind, leading to holistic transformation. This journey isn't confined to the yoga studio; it extends to your dining habits as well. Maintaining a healthy diet is key.",
      "At our center, we offer fully vegetarian meals (though not vegan). If you have specific dietary needs, please inform us in advance so our chef can accommodate your requirements. Simply reach out with your inquiry, and we'll ensure your needs are met."
    ],
    listItems: [],
  
  },

    { 
    id: 2,
    h4Text:"",
    h5Text: "The following meals are included:",
    pText: [],
    listItems: ["Breakfast",
      "Lunch",
      "Dinner",
      "Drinks"
    ],
  
  },
  { 
    id: 3,
    h4Text: "",
    h5Text:"The following drinks are included:",
    pText: [],
    listItems: ["Water","Tea"],
  
  },

    { 
    id: 4,
    h4Text: "",
    h5Text:"The following dietary requirement(s) are served and/or catered for:",
    pText: [],
    listItems: ["Vegetarian","Other dietary requirements on request"],
  
  },
]

export const courseIncluExcluData = [
  { 
    id: 1,
    h4Text: "Inclusions",
    pText: [],
    listItems: ["25 nights accommodation",
      "Rooms with hot water, attached bath, and toilet",
      "Unlimited free high-speed WiFi internet connection",
      "3 times daily nutritious vegetarian meals and teas",
      "Course books and course manual",
      "Cleansing material like Neti pot, rubber tube, etc.",
      "One Himalayas trip",
      "Usage of in-house library",
    ],
  
  },

  
  { 
    id: 2,
    h4Text: "Exclusions",
    pText: [],
    listItems: ["Flights",
      "Airport transfers",
      "Air-conditioner",
      "Room heater",
    ],
  },

]

export const courseLocation = [
  { 
    pText: [
      "Located in the vibrant Tapovan area, Rishikesh Yoga Association campus offers an ideal setting for yoga practice. Surrounded by lush greenery, the area receives refreshing mountain breezes, infusing the atmosphere with positive energy.",
      // "Rishikesh is renowned for its spiritual ambiance, with the holy Ganges River flowing through its heart. This sacred environment enhances the yogic experience, making it even more enriching.",
      // "Our school provides breathtaking views of the Himalayas and the majestic Ganges, just a short walk away. Immerse yourself in nature during your yoga teacher training in Rishikesh, where you may encounter beautiful birds and animals like cows or squirrels, fostering a sense of harmony and coexistence.",
      // "In addition to exceptional training, our dedicated staff ensures a comfortable and enjoyable stay for all students. From accommodations to meals, we treat you like family, ensuring a seamless experience throughout your journey."
    ]
  }
]






