export default [
  
  {
    id: 1,
    treatmentName: "Shirodhara",
    treatmentImg: "treatment1_img.png",
    treatmentText: "Let warmed herbal oil, takra, or milk flow gently onto your third eye or forehead, promoting memory enhancement, stress relief, and relaxation. Say goodbye to anxiety and fatigue while enjoying healthier hair and relief from headaches.",
    treatmentBenefits:"rejuvenating memory enhancing, relaxes mind revitalise mind and body removes anxiety, stress, good for hair ,head ache and fatigue",
  },

  {
    id: 2,
    treatmentName: "Udwartan(Herbal Powder Massage)",
    treatmentImg: "treatment2_img.png",
    treatmentText: "After a full-body massage with dosha-specific oil, indulge in a deep exfoliating herbal powder massage. Experience benefits such as weight reduction, improved blood circulation, and the removal of dead skin cells, ideal for balancing Kapha dosha.",
    treatmentBenefits:"good for kapha, reducing weight, improves blood circulation, removes dead cells",
  },

  {
    id: 3,
    treatmentName: "Ubtan Abhyanga Herbal",
    treatmentImg: "treatment3_img.png",
    treatmentText: "After a full-body massage with dosha-specific oil, indulge in a deep exfoliating herbal powder massage. Experience benefits such as weight reduction, improved blood circulation, and the removal of dead skin cells, ideal for balancing Kapha dosha.",
    treatmentBenefits:"Beautiful Skin, Refresh Body Tissue",
  },

]


