import React from "react";
import HeadingBox from "../HeadingBox.js";
import ContentItems from "../../ContentItems.js"
// import ProgramTextBox from "./ProgramTextBox.js";
import program_icon from "../../../assets/images/retreat_page_images/program-icon.svg";
import program_yoga_girl from "../../../assets/images/retreat_page_images/program-yoga-girl.svg";
import program_grass_illustration from "../../../assets/images/retreat_page_images/program-grass-illustration.svg";
import program_section_data from "../../../data/retreat_data/atharva_retreat_data/atharva_program_section_data";
// import ProgramSection2 from "./ProgramSection2.js"

export default function ProgramSection(){
  
  const programContent = program_section_data.map(item => {
    return (<ContentItems 
        h4Text={item.h4Text}
        pText={item.pText}
        listItems={item.listItems}
    />)
  })
  
  return ( <section className="atharva-program-section">
    <HeadingBox 
      iconImg = {program_icon}
      retreatH2 = "Program"
    />
    <img className="atharva-program-girl" src={program_yoga_girl}/>
    <div className="content-card container">
      {programContent}
    </div>
    


    <img className="program-grass-img" src={program_grass_illustration}/>
    <div className="background-rect-filled"></div>
    <div className="background-rect-border"></div>
  </section>)
}