import React from "react"
import H4Comp from "./H4Comp.js";
import { useNavigate } from "react-router-dom";



export default function QueryForm(props){
  
  const navigate = useNavigate
  const queryType = props.queryType
  const [formData, setFormData] = React.useState({
    email: "",
    query: "",
  })

  function handleChange(event){ 
    setFormData(prevFormData => ({
      ...prevFormData, [event.target.name]: event.target.value
    }))
  }

  function handleSubmit(event){
    event.preventDefault()
    console.log("form has submitted")
    console.log(formData)

    const { queryType, email, query,} = formData
    // navigate("/course/payments/confirmation")
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify({
          email, query
        })
    }
    const res = fetch(`https://yogsidhi-site-default-rtdb.asia-southeast1.firebasedatabase.app/${props.saveDataTo}.json`, options)  
  }


  return (<form onSubmit={handleSubmit} className= "query-form">
    
    <div className="query-form-header">
      <H4Comp 
      h4Text= "Send Inquiry"
      />
    </div>

    <div className="query-form-body">

     { props.saveDataTo === "panchkarmaUserData" && 
      <p className="small-text">
          Type your query regarding Therapeutic Treatments below, we will get back to you. Response time is 2-3 hrs between 9 am to 7 pm.
        </p>
        ||
        <p>
          If you have any question about this course, pricing, accomodation etc. type your message here and we will get back to you. 
        </p>
      }
      
      <label htmlFor="query">Query</label>
      <textarea 
        className= "text-query-box" 
        rows="5" 
        type="text"
        placeholder="Type your query here"
        onChange={handleChange}
        name="query"
        value={formData.query}
      />

      <div className="email-box-area">
        <label htmlFor="email">Email</label>
        <br />
        <input className="email-text"
          type="email"
          placeholder="Enter Email id"
          onChange={handleChange}
          name="email"
          value={formData.email}
        />
      </div>

      <br/>

      <button className="primary-btn-dark">Send Inquiry Now</button>
    </div>
  </form>)
}