import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import course_yoga1_img from "../assets/images/course_page_images/rya1.png"
import course_yoga2_img from "../assets/images/course_page_images/rya2.png"
import course_yoga3_img from "../assets/images/course_page_images/rya3.png"

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
  breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1076 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1076, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export default function Carousal(props){
 const imagesPath = props.imagePath
  const carousal_image = props.galleryImg.map(item => {
    return (<div>
      <img src={require (`../assets/images/${props.imagePath}/${item}`)}/>
    </div>)
  })

  return( <Carousel responsive={responsive}>
    {carousal_image}

   <div>
      <img src={course_yoga2_img}/>
    </div>
    <div>
      <img src={course_yoga3_img}/>
    </div>
    <div>
      <img src={course_yoga1_img}/>
    </div>
    <div>
      <img src={course_yoga2_img}/>
    </div>
    <div>
      <img src={course_yoga3_img}/>
    </div>
    ${props.imagePath}
  </Carousel>);
}

  