import React from 'react';
import ContentItems from "../ContentItems.js"
import {courseOverviewData} from "../../data/YTT_RYA_data.js"
import H2Comp from "../H2Comp.js";
import H4Comp from "../H4Comp.js";
import ParaComp from "../ParaComp.js";
import ULComp from "../ULComp.js";


export default function CourseOverview(){
 

  const overview_content_items = courseOverviewData.map((item, idx) => {
    return (
      <div key={`overview-${idx}`}>
        <ContentItems 
            h4Text={item.h4Text}
            pText={item.pText}
            listItems={item.listItems}
        />
      </div>
    )
  })

  return (<div className="content-card">
    <H2Comp
        h2Text="Overview"
      />
    {overview_content_items}
  </div>)
}
