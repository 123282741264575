import React from 'react';
import TopNavigation from "../components/TopNavigation";
import header_image from "../assets/images/AboutUs_page_images/about_us_yoga_illustration.svg";
import about_us_icon from "../assets/images/AboutUs_page_images/abt_us_icon.svg";
import whoweare_illust_1 from "../assets/images/AboutUs_page_images/whoweare_illust_1.svg";
import whoweare_illust_2 from "../assets/images/AboutUs_page_images/whoweare_illust_2.svg";
import whoweare_illust_3 from "../assets/images/AboutUs_page_images/whoweare_illust_3.svg";
import whoweare_bg from "../assets/images/AboutUs_page_images/who_we_are_bg.svg";
import { motion } from "framer-motion"

class AboutPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return <div>
			<div className="abt-us-header container">
				<h1 className="abt--h1">About Us</h1>
				<rect className="abt-bg-rect"></rect>
				<img className="abt-us-icon" src={about_us_icon}/>
			</div>
			<div className="abt-yogsidhi container">
				<div className='header-img-box'>
					<motion.img 
						animate={{ rotate: 360 }}
						transition={{ ease: "linear", duration: 25, repeat: Infinity }}
						src={header_image} 
					/>
				</div>
				<div className="abt-yogsidhi-content">
					<h2 className="abt--h2">YogSidhi</h2>
					<h4 className="abt--h4">YogSidhi is a product for yoga & ayurveda enthusiasts created by  pentartstudio based in Uttarakhand. We are a group of software engineers, UI/UX designers & Yoga Teachers. </h4>
					<p className="abt-p">At Yogsidhi we offer yoga teacher training courses, as well as yoga retreats and Ayurveda retreats in Rishikesh & Dehradun</p>
				</div>
			</div>

			<section className="who-we-are-section container">
				<img className="whoweare-bg" src={whoweare_bg}/>
				<h2 className="abt--h2">Who we are</h2>
				<div className="whoweare-illust">
					<img src={whoweare_illust_1}/>
					<img src={whoweare_illust_2}/>
					<img src={whoweare_illust_3}/>
				</div>
				<div className="whoweare-content">
					<p className="whoweare-subhead">Group of Designer, Developer & Yoga Enthusiast working 
					in harmony to create an amazing experience for You!</p>
					<p className="whoweare-body">working from the mountains of Himalayas, Our team is 
					dedicated to helping people transform their lives for the better. 
					Through our yoga and meditation programs, we provide people with the 
					tools they need to lead healthy, happy, and productive lives.</p>
				</div>
			</section>
		</div>
	}	
}

export default AboutPage;