import React from "react"
import H2Comp from "./H2Comp.js";



export default function QueryForm(){
  
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    context: "",
    message: "",
  })

  function handleChange(event){
    console.log(event)
    
    setFormData(prevFormData => ({
      ...prevFormData, [event.target.name]: event.target.value
    }))
  }

  function handleSubmit(event){
    event.preventDefault()
    console.log("form has submitted")
  }


  return (<form onSubmit={handleSubmit} className= "contactform_box">
    


    <div>
      <h2 className="contact--h2">How can we help you?</h2>
      <p className="contactform_subhead">
        Send your query here and we will get back to you ASAP. 
      </p>
      <p className="contactform_disclaimer">
      Our team will respond to you as quickly as possible, normally within 1 business day.
      </p>
      <div className="email-box-area">
        <label htmlFor="name">Name</label>
        <br />
        <input className="email-text"
          type="text"
          placeholder="Enter Your Full Name"
          onChange={handleChange}
          name="name"
          value={formData.name}
        />
      </div>


      <div className="email-box-area">
        <label htmlFor="email">Email</label>
        <br />
        <input className="email-text"
          type="email"
          placeholder="Enter Email id"
          onChange={handleChange}
          name="email"
          value={formData.email}
        />
      </div>

      <div className="email-box-area">
        <label htmlFor="context">Context</label>
        <br />
        <input className="email-text"
          type="text"
          placeholder="Type the context of your query"
          onChange={handleChange}
          name="context"
          value={formData.context}
        />
      </div>

      <label htmlFor="message">Message</label>
      <br />
      <textarea 
        className= "text-query-box" 
        rows="5" 
        type="text"
        placeholder="Type your message here"
        onChange={handleChange}
        name="message"
        value={formData.message}
      />

      <br/>

      <button className="primary-btn-dark margin-32">Send Inquiry Now</button>
    </div>
  </form>)
}