import React from "react"
import H2Comp from "../H2Comp.js";
import LabelValueComponent from "../LabelValueComponent.js";
import ContentItems from "../ContentItems.js"
import {courseFoodData} from "../../data/YTT_RYA_data.js";
import food_img1 from "../../assets/images/retreat_page_images/food-img1.png";
import food_img2 from "../../assets/images/retreat_page_images/food-img2.png";
import food_img3 from "../../assets/images/retreat_page_images/food-img3.png";



export default function CourseFood(){
  
  const course_food_content = courseFoodData.map(item => {
    return (<ContentItems 
        h4Text={item.h4Text}
        h5Text={item.h5Text}
        pText={item.pText}
        listItems={item.listItems}
      />)
  })

  return (<div className="content-card">
    <H2Comp
        h2Text="Food"
      />

      <div className="food-images-box margin-32">
          <img src={food_img1}/>
          <img src={food_img2}/>
          <img src={food_img3}/>
      </div>
      {course_food_content}
  </div>)
}