import React from 'react';
import TopNavigation from "../components/TopNavigation";
import contact_icon from "../assets/images/contact_images/contact_icon.svg";
import contact_bg from "../assets/images/contact_images/contact_bg.svg";
import contactform_illust from "../assets/images/contact_images/contactform_illustration.svg";
import contactinfogirl_illust from "../assets/images/contact_images/contactinfogirl_illustration.svg";
import email_icon from "../assets/images/contact_images/email_icon.svg";
import whatsapp_icon from "../assets/images/contact_images/whatsapp_icon.svg";
import copy_icon from "../assets/images/contact_images/copy.svg";
import fb_icon from "../assets/images/contact_images/fb_icon.svg";
import insta_icon from "../assets/images/contact_images/insta_icon.svg";

import ContactForm from "../components/ContactForm.js";



class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div>
      <TopNavigation />
      <div className="contactus-header container">
        <img className="contact_icon" src={contact_icon}/>
        <h1 className="contactus--h1">Contact Us</h1>
       </div>
      <div className="contactus_content container">
        <div className="contact_info">
          <div className="contact-info-content">
            <h2 className="contact--h2">Contact Info</h2>
            <div className="contact-detail-box">
              <img src={email_icon}/>
              <p className="contact-email">info@yogsidhi.com </p>
              <img src={copy_icon}/>
            </div>
            <div className="contact-detail-box">
              <img src={whatsapp_icon}/>
              <a aria-label="Connect Now" href="https://wa.me/916398669195?text=hi" className="contact-whatsapp-link">Connect Now</a>
            </div>
            <div className="contact-social-box">
                <a href=""><img src={fb_icon}/></a>
                <a href=""><img src={insta_icon}/></a>
            </div>
          </div>
          <img className="contactinfogirl-illust" src={contactinfogirl_illust} />
        </div>
        <div className="contact-form-area">
          <ContactForm />
          <img className="contactform-illust" src={contactform_illust}/>
        </div>
        <img className="contact-bg" src={contact_bg}/>
      </div>
    </div>
  } 
}

export default ContactPage;