import React from "react";
import HeadingBox from "./HeadingBox.js";
import highlight_icon from "../../../assets/images/retreat_page_images/highlight-icon.svg";
import doctor_img from "../../../assets/images/retreat_page_images/doctor-img.svg";



export default function HighlightSection(){
  return ( <section className="highlight-section">
    <HeadingBox 
      retreatH2="Highlights"
      iconImg= {highlight_icon}
    />

    <div className="doc-img-box retreat-container">
        <img className="doctor-img" src={doctor_img} alt="doctor-3d-illustration"/>
    </div>

    <div className="highlight-text-box retreat-container">
      <div className="four-col-row">
          <div className="column"> <p>Free ayurvedic Consultation</p> </div>
          <div className="column"> <p>Interactive nutrition talk</p> </div>
          <div className="column"> <p>Psychic sleep meditation</p> </div>
          <div className="column"> <p>Daily asana, meditation and pranayama classes</p> </div>
          <div className="column"> <p>Nutritious vegetarian meals & herbal tea</p> </div>
          <div className="column"> <p>6 nights accommodation</p> </div>
          <div className="column"> <p>Enjoy nature excursions</p> </div>
          <div className="column"> <p>Airport transfer</p> </div>
          {/*<div className="column"> <p>Comfortable accommodation</p> </div>*/}
      </div>
{/*      <div className="row">
          <div className="column"> <p>Mantra chanting and kirtan</p> </div>
          <div className="column"> <p>Fire ceremony with mantra</p> </div>
          <div className="column"> <p>Indian philosophy session</p> </div>
          <div className="column"> <p>Comfortable accommodation</p> </div>
      </div>*/}
    </div>
  </section>)
}
