import React from "react";

import { SiteContext } from "../App";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";

export default function SecondaryNav({ sendBackLink, children }){
    const {secondNavText, setSecondNavText, backLink, setBackLink} = React.useContext(SiteContext)
    // const location = useLocation()

    // let from = location.state?.from?.pathname || location.pathname;
    // console.log(from)
    return(
        <div className="secondary-nav">
            <Link 
                to={sendBackLink} 
                width="1.5rem"
                height="1.5rem"
                // color="#24292B" 
            >
                <IoIosArrowBack
                    style={{width: "1.5rem", height: "1.5rem", alignItems: "center", display: "flex", fill:"#3E4F55"}}
                />
            </Link>
            <h3>{children}</h3>
        </div>
    )
}

// export function setSecondNavText({children}){
//     return children
// }