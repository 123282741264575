import React from 'react';
import course_css from "../../assets/css/course_page_css.scss"
import TopNavigation from "../../components/TopNavigation.js";
import HeaderImage from "../../components/HeaderImage.js"
import header_image from "../../assets/images/home_page_images/course-img-header.png";
// import CourseOverview from "../components/course_components/CourseOverview.js"
import MainContainer from "../../components/rya_course_components/MainContainer.js";
import DetailPageHeader from "../../components/DetailPageHeader.js";
import { useContext } from 'react';
import { createContext } from 'react';

import { SiteContext } from '../../App.js';

export const CourseContext = createContext()

export default function CoursePage() {
    const courseInfo = {
      type: "Course",
      name: "26 Day 200-Hour Ancient Ashtanga Yoga Teacher Training in Rishikesh",
      thumbnailImg: "selected-course-img.png"
    }
    const {headerType, setHeaderType} = React.useContext(SiteContext)

    // React.useEffect(()=>{
    //     setHeaderType("Top Navigation")
    // },[])

    return (
      <CourseContext.Provider value = {{courseInfo}}>
        {/*      <HeaderImage 
                href={header_image}
                heading1="Kundalini Yoga Teacher Training - 1 Week 100Hrs"
              />*/}

        <DetailPageHeader 
          h1Text="26 Day 200-Hour Ancient Ashtanga Yoga Teacher Training in Rishikesh"
        />

        <MainContainer />

      </CourseContext.Provider>
    )
}

