import React from "react";
import H1Comp from "./H1Comp";
import Carousal from "./Carousal";
import rya_image_gallery_data from "../data/course_data/rya_image_gallery_data.js";


export default function DetailPageHeader(props){
  
  // const carousal = rya_image_gallery_data.map( item => {
  //   return (<Carousal
  //       key={item.id} 
  //       imagePath= "course_page_images"
  //       galleryImg={item.galleryImg}
  //     />)
  // })
    

  return  (<div className="top-header container">
      <H1Comp
        className="detail_page_h1"
        h1Text={props.h1Text} />
        {/* {carousal} */}
    </div> )
}


