import React from 'react';
// components Import
import RyaBookingCalendar from './RyaBookingCalendar.js';
import CoursePackageSelection from './CoursePackageSelection.js';
// router hooks Import
import { useLocation, useNavigate } from 'react-router-dom';

// Context Import
import { CourseContext } from '../../pages/Course/RyaCoursePage.js';

// Form Context Creation
export const FormContext = React.createContext()

export default function RyaCourseForm1(){

    const [formData, setFormData] = React.useState({
        selectedDate: "",
        selectedPackage: "",
    })

    const [isDisabled, setIsDisabled] = React.useState(false)

    // const [submited, setsubmited] = React.useState(false)
     
    const navigate = useNavigate()
    const location = useLocation()
    const { courseInfo } = React.useContext(CourseContext)

    function handleRequestToBook(){
        navigate("/course/contact-form", { state: {courseInfo, formData}} )
        document.body.style.overflow = 'unset';
    }

    // React.useEffect(()=>{
    //     // if(formData.selectedDate && formData.selectedPackage)
    //     // {setIsDisabled(true)}
    //     // else{
    //     //     setIsDisabled(false)
    //     // }
    //     console.log(formData.selectedDate, formData.selectedPackage)
    // },[formData])

    return(
        <FormContext.Provider value={{formData, setFormData }}>
            <div className="query-form-header">
                <h3 style={{color: "#ffffff"}}>Request to Book</h3>
            </div>
            <h4>24 days / 23 nights </h4>
            <RyaBookingCalendar />
            <CoursePackageSelection />
            <button 
                disabled = {!formData.selectedPackage.id || !formData.selectedDate} 
                onClick = {handleRequestToBook} 
                className='primary-btn-color'
            >
                Request to Book
            </button>
        </FormContext.Provider> 
    )
}