import React from "react";
import selectedCourseImg from "../../assets/images/course_page_images/selected-course-img.png"
import LabelValueComponent from "../LabelValueComponent";

import { useNavigate, useLocation, Navigate } from "react-router-dom"
import {CourseContext} from "../../pages/Course/RyaCoursePage"
import { UserContext } from "../../pages/Course/RyaCoursePaymentPage";



export default function SelectedCoursePackage(){
    // const courseInfo = React.useContext(CourseContext)
    const location = useLocation()
    const navigate = useNavigate()    

    const { courseInfo, formData } = location.state


    const {userAllData, setUserAllData} = React.useContext(UserContext)

    const{ selectedPackage, selectedDate } = formData
   
    const duePayment = selectedPackage.price - selectedPackage.price * 30 / 100
    const advancePayment = selectedPackage.price - duePayment
    
    const dateItemsArr = formData.selectedDate.split(" ", 3)
    const dateNumItem = dateItemsArr[2]

    const d = new Date(selectedDate)
    // d.setDate(dateNumItem)

    let startDate = d.toDateString().split(" ")

    startDate.shift()
    startDate = startDate.join(" ")

    const endDateNum = d.getDate() + 27
    const e = new Date(selectedDate)
    e.setDate(endDateNum)

    let endDate = e.toDateString().split(" ")
    endDate.shift()
    endDate = endDate.join(" ")


    React.useEffect(()=>{
        setUserAllData(prevData => {
            return{...prevData, courseInfo: courseInfo, selectedDate: selectedDate, selectedPackage: selectedPackage}
        })
    },[])

    // React.useEffect(()=>{
    //     return () => navigate(location.pathname, {})
    // },[location])
    return(
        <div className="selected-course-package-box">
            <div className="selected-course-info-box">
                <div className="selected-course-img">
                    <img  src={require(`../../assets/images/course_page_images/${courseInfo.thumbnailImg}`)} />
                </div>
                <div>
                    <span><p>Retreat in Rishikesh</p></span>
                    <h5 className="selected-course-heading">{courseInfo.name}</h5>
                </div>
            </div>  

            <div className="selected-package-area">
                <div className="selected-package-info">
                    <p className="selected-person">{formData.selectedPackage.person}</p>
                    <p className="selected-room">{selectedPackage.room}</p>
                </div>
                <div className="selected-duration">
                    <LabelValueComponent 
                        label={`${courseInfo.type} Start Date`}
                        value={startDate}
                    />

                    <LabelValueComponent 
                        label={`${courseInfo.type} End Date`}
                        value={endDate}
                    />
                </div>
            </div>  
            <div className="price-details-area">
                <div className="price-details">
                    <p className="total-price-label">Total Price</p>
                    <p className="total-price-value">₹ {selectedPackage.price}</p>
                </div>

                <div style={{display: "none"}} className="price-breakup-box">
                    <div className="price-details">
                        <p className="regular">To be paid at retreat center </p>
                        <p className="regular">₹ {duePayment}</p>
                    </div>
                    <div className="price-details">
                        <p className="bold">Booking Amount</p>
                        <p className="bold">₹ {advancePayment}</p>
                    </div>
                </div>
            </div>     
        </div>
     )
}