export const panchkarmaPageData = {
    about: {
        heading: "Purification and Rejuvenation Therapy",
        pText: "Experience the ancient science of Ayurveda with our Panchkarma therapy, aimed at eliminating toxins, restoring balance to the body's doshas (Vata, Pitta, Kapha), and enhancing immunity. Rediscover vitality and well-being with treatments tailored to your unique constitution."
    },
    consultation:{ 
        heading: "Ayurveda Consultation",
        pText: "Receive personalized guidance from our experienced Ayurvedic doctors, who determine your body type (Prakruti) and provide tailored diet plans, herbal medicines, and therapy recommendations."
    },
    treatments:{
        heading: "Therapeutic Treatments",
        treatmentTypes :[
            {
                heading: "Shirodhara",
                pText: "Let warmed herbal oil, takra, or milk flow gently onto your third eye or forehead, promoting memory enhancement, stress relief, and relaxation. Say goodbye to anxiety and fatigue while enjoying healthier hair and relief from headaches."
            },

            {
                heading: "Udwartan (Herbal Powder Massage)",
                pText: "After a full-body massage with dosha-specific oil, indulge in a deep exfoliating herbal powder massage. Experience benefits such as weight reduction, improved blood circulation, and the removal of dead skin cells, ideal for balancing Kapha dosha."
            },

            {
                heading: "Ubtan Abhyanga",
                pText: "Experience the luxurious application of herbal mustard paste infused with special herbs all over your body. Enjoy the benefits of beautiful skin and refreshed body tissues, leaving you feeling rejuvenated."
            },

            {
                heading: "Sugandh Abhyangam (Massage with Aromatic Oils)",
                pText: "Treat yourself to a massage with aromatic oils tailored to your dosha. Refresh your body, mind, and soul while experiencing the soothing effects of herbal aromas."
            },

            {
                heading: "Pind Swedana (Herbal Bundle Massage)",
                pText: "Ease muscular and joint pain with a gentle massage using a special bundle of herbs following an herbal oil massage. This treatment is particularly beneficial for those with aggravated Vata dosha."
            },

            {
                heading: "Chakra Abhyanga",
                pText: "Indulge in a calming chakra massage using the flow of herbal oils to balance and energize your chakras. Experience improved energy flow and relaxation with this specialized treatment."
            },       
        ],

        additionalTreatments: [
            {
                heading: "Head, Shoulder, and Back Massage",
                pText: "Target specific areas to relieve pain, tenderness, and stiffness.",
            },
            {
                heading: "Pizhichil",
                pText: "Enjoy a rejuvenating whole-body oil bath, perfect for softening body tissues and strengthening immunity.",
            },
            {
                heading: "Akshi Tarpan Netra Basti (Eye Treatment)",
                pText: "Experience cooling relief for stressed eyes, improved eyesight, and the reduction of dark circles.",
            },

            {
                heading: "Kati Basti",
                pText: "Alleviate back, lumbar, sciatica, and spinal pain with specialized herbal oil treatments.",
            },

            {
                heading: "Shashti Shali Massage (Milk Rice Herbal Massage)",
                pText: "Nourish your body tissues and enhance skin glow with this unique massage.",
            },
        ],    
    },

    relaxationPackages: {
        heading: "Relaxation Packages",
        pText:"Choose from a variety of packages combining different Ayurvedic therapies for a comprehensive wellness experience, including options for herbal medicine supply and Ayurveda & Yoga training."
    },

    relaxationPackagesList: [
        {
            heading: "Abhyanga, Shirodhara & Steam",
            pText: "Enjoy the soothing benefits of an Abhyanga massage followed by a rejuvenating Shirodhara treatment and relaxing steam therapy. Feel tension melt away as your body and mind are pampered with gentle care."
        },

        {
            heading: "Herbal Package",
            pText: "Experience the healing power of nature with our herbal package, combining Abhyanga massage, Shirodhara, steam therapy, and a one-month supply of herbal medicines. Enhance your wellness journey with the natural goodness of Ayurvedic herbs."
        },
        {
            heading: "Panchendriya Package",
            pText: "Immerse yourself in a comprehensive wellness experience with Abhyanga massage, Netra Basti (eye treatment), Nasyam (nasal therapy), Karna Pooran (ear treatment), and Kawala (oral cleansing). Rebalance your senses and revitalize your body from head to toe."
        },
        {
            heading: "Basic Ayurveda & Yoga Training",
            pText: "Embark on a journey of self-discovery and holistic healing with our basic Ayurveda & Yoga training package. Immerse yourself in daily theory and practical classes led by expert Ayurvedic doctors and yogacharyas. Experience the transformative power of ancient wisdom combined with modern insights."
        },
    ]
    
}