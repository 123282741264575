export default [
  
  {
    id: 1,
    studentName: "Sophie",
    studentImg: "sophie_img.svg",
    testimonialText: "Hello, I deeply appreciate your enlightening teachings and the warmth of your smile that ignites my spirit and enriches my understanding. Your kindness is truly cherished, and I am grateful for the purity of your guidance. I look forward to our continued connection and future encounters in India. Thank you.",
  },

  // {
  //   id: 2,
  //   studentName: "Clera",
  //   studentImg: "clera_img.svg",
  //   testimonialText: "Lovely to meet you to be your student to feel how you share Ayurveda with compassion, freedom in a true way a real journey through Ayurveda I feel blessed to be here during this training I thanks a lot for that sharing love and kindness.",
  // },

  // {
  //   id:3,
  //   studentName: "Michelle",
  //   studentImg: "michelle_img.svg",
  //   testimonialText: "Thank you so much, Dr harsh for giving me crumbs of stars for my award. Send me an angel and you are this angel",
  // },

]




// Lovely to meet you to be your student to feel how you share Ayurveda with compassion, freedom in a true way a real journey through Ayurveda I feel blessed to be here during this training I thanks a lot for that sharing love and kindness.