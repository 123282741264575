import React from 'react';
import whatsapp_icon from "../assets/images/footer_images/whatsapp_icon.svg";
import message_icon from "../assets/images/footer_images/message_duotone.svg";
import copy_icon from "../assets/images/footer_images/footer_copy_icon.svg";
import pentartstudio_logo from "../assets/images/footer_images/pentartstudio_logo.svg"


class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className="footer-section">
      <div className="footer-content container">
        <div className="footer-block">
          <p className="footer-small-text">Made with ❤️ by</p>
          <img className="pentart-logo" src={pentartstudio_logo} />
          <p className="footer-small-text">from the mountains of Himalayas.</p>
        </div>
        <div className="footer-block">
          <ul className="generic-list">
            <li className="footer-link">
              <a href="/about">About Us</a>
            </li>
            <li className="footer-link">
              <a href="/contact">Contact Us</a>
            </li>
            <li className="footer-link">
              <a href="#">Blogs</a>
            </li>
            <li className="footer-link">
              <a href="#">Terms & Conditions</a>
            </li>
          </ul>
        </div>
        <div className="footer-block">
          <ul className="generic-list">
            <li className="footer-link">
              <a href="/retreat">Retreats</a>
            </li>
            <li className="footer-link">
              <a href="/course">Yoga Teacher Training 200Hrs</a>
            </li>
            {/* <li className="footer-link">
              <a href="#">Panchkarma</a>
            </li>
            <li className="footer-link">
              <a href="#">Ayurvedic Healings</a>
            </li> */}
          </ul>
        </div>
        <div className="footer-block contact-block">
          <div className="footer-contact-link">
            <img src={whatsapp_icon}/>
            <p>connect now</p>
          </div>
          <div className="footer-contact-link">
            <img src={message_icon}/>
            <p>info@yogsidhi.com</p>
            <img src={copy_icon} />
          </div>
          <div className="copyright-text">
            <p className="footer-small-text">Copyright YogSidhi © 2021. All rights reserved.</p>
            <p className="footer-small-text">thanks for the icons icons8.com</p>
          </div>
        </div>
      </div>

    </div>;
  }
}

export default Footer;