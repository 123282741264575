import React from "react";
import { Dayjs } from "dayjs";
import { getDate } from "date-fns";

import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import { FormContext } from "./RyaCourseForm1";

import moment from "moment";

const curYear = moment().year()
const curMonth = moment().month()
const curDate = moment().date()

// const endDateEl = moment().date().add(27, 'days')

// const endDate = endDateEl._d.toDateString()
// const formatedEndDate = 

// console.log(endDate._d.toDateString())

export default function RyaBookingCalendar(){

    const {formData, setFormData} = React.useContext(FormContext)
    const [selectedDate, setSelectedDate] = React.useState(null)

  const  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const retreatStartDates = [1, 
                        // 6, 9, 13, 16, 20, 24
                    ]
    //  console.log(formData)
    const date = new Date()
    const currentDate = new Date()
    const [currentYear, setCurrentYear] = React.useState(curYear)
    const [currentMonth, setCurrentMonth] = React.useState(curMonth+2)


    const [selectedtMonth, setSelectedtMonth] = React.useState(currentMonth)

    const updatedMonth = new Date(`${currentYear} 0${selectedtMonth} ${date.getDate(1)}`)
    


    function handleChange(event){
        setSelectedDate(event.target.value)
    }

    React.useEffect(()=>{
        setFormData(prevData => {
            return {...prevData, selectedDate: selectedDate}
        })
    }, [selectedDate])


    function getNextMonth(){
        setSelectedtMonth(prevMonth => prevMonth + 1)
        if(selectedtMonth === 12){
            setCurrentYear(prevYear => prevYear + 1)
            setSelectedtMonth(date.getMonth(0))
        }
    }


    function getPreviousMonth(e){
        if(selectedtMonth === currentMonth){
            return
        }

        if(selectedtMonth === 1){
            setCurrentYear(prevYear => prevYear - 1)
            setSelectedtMonth(13)
        }

        setSelectedtMonth(prevMonth => prevMonth - 1)
    }

    function getStartDates(startDate, dateEl){
        // const dateEl = new Date()
        
        let startDateEl 
        dateEl.setDate(startDate)
        return startDateEl = dateEl.toDateString()
        // let startDateWthoutYear = startDateEl.split(" ", 4)
        // const [a, b, c, d] = startDateWthoutYear
        // const formatedDate = [a, c, b]
        // startDateWthoutYear.pop()
        // return formatedDate.join(" ")
        
    }

    React.useEffect(()=>{
        if(updatedMonth.getTime() < currentDate.getTime()){
            setSelectedtMonth(prevMonth => prevMonth + 1)
        }
    },[updatedMonth, currentDate])

    


    const radioEl = retreatStartDates.map((startDate, idx) => {
        const startDateEl = getStartDates(startDate, updatedMonth)
        
        const retreatDate = updatedMonth
        retreatDate.setDate(startDate)
    
        // if (retreatDate.getTime() < currentDate.getTime()) {
        //     console.log(`${retreatDate} is earlier than ${currentDate}`);
        // } else if (retreatDate.getTime() > currentDate.getTime()) {
        //     console.log(`${retreatDate} is later than ${currentDate}`);
        // } else{
        //     console.log(`${retreatDate} is same as ${currentDate}`)
        // }


        

        let startDateWthoutYear = startDateEl.split(" ", 4)
        const [a, b, c, d] = startDateWthoutYear
        const formatedDate = [c, b]
        console.log(formatedDate)
        
        return (
            <label 
                key={idx} 
                htmlFor={idx}
                className={`${selectedDate === `${startDateEl}` 
                    ? "selected-radio-label" 
                    : ""} 
                    course-radio-label 
                    ${retreatDate.getTime() <= currentDate.getTime()
                    ? "disabled-radio" 
                    : ""}`}
                
            > 
                    <input 
                        type="radio" 
                        name="calendar"
                        value={`${startDateEl}`}
                        id={idx}
                        checked={selectedDate === `${startDateEl}`}
                        onChange={handleChange}
                        disabled={retreatDate.getTime() <= currentDate.getTime()} 
                    />
                {`${startDate} to ${startDate+23} ${months[selectedtMonth - 1]}`} 
            </label>   
        )
    })

    return(
        <div className="custom-cal">
            <h5>Select Start Month & Date</h5>
            <div className="month-box">
                <SlArrowLeft color={selectedtMonth === currentMonth && "#BDCFD4"} onClick={getPreviousMonth} />
                <p>{months[selectedtMonth-1] + " " + currentYear}</p>
                <SlArrowRight onClick={getNextMonth} />
            </div>
            {/* <p className="start-date-label">Select Retreat Start Date</p> */}
            <div className="reatreat-date-selecton-box">
                {radioEl}
            </div>
        </div>
    )
}