import React from "react";
import HeadingBox from "./HeadingBox.js";
import ScheduleContentCard from "./ScheduleContentCard.js";
import TransportTypeComp from "./TransportTypeComp.js"
import location_icon from "../../../assets/images/retreat_page_images/location-icon.svg";
import location_img from "../../../assets/images/retreat_page_images/location-img.svg";

export default function LocationSection(){


  function goToLocation(){
    const myLocation = "https://www.google.com/maps/place/Atharv+Yogshala/@30.0129375,78.2167376,17z/data=!3m1!4b1!4m6!3m5!1s0x39093e5847c4b5e5:0x1277bcce73dbc8a8!8m2!3d30.0129375!4d78.2193125!16s%2Fg%2F11lgdvxtfv?entry=ttu"
    window.open(myLocation, '_blank')
  }  


  return (<section className="loaction-section">

    <HeadingBox 
      iconImg = {location_icon}
      retreatH2 = "Location"
    />

    <div className="loaction-content-card container">
      <div className="adress-box">
          <div>
              <p className="address-text">Atharv Yogshala, 89-90 Lakkad Ghat Rd Nirmal Block C, Birpur Khurd Lakkad Ghat, Rishikesh, Uttarakhand 249204, India</p>
              <p className="address-decription">The accommodation is situated in a beautiful quiet place, just outside of Rishikesh. 
              You will be surrounded by nature and you will feel a calmness come over you as soon as you enter the accommodation.</p>
          </div>
          <div className="location-visual-box">
              <img className="location-img" src={location_img}/>
              <button onClick={goToLocation} className="view-map-btn">View Map</button>
          </div>
      </div>
      <div className="transport-box">
        <h4 className="retreat--h4">How to Reach</h4>
        <TransportTypeComp 
          img="by-air-icon.svg"
          transportType="By Air"
          label1="Nearest Airport"
          value1="DED - Jolly Grant Airport"
          distance1="24 Kms"
          label2="International Airport"
          value2="DEL - Indira Gandhi International Airport"
          distance2="260 Kms"
        />

        <TransportTypeComp 
          img="by-bus-icon.svg"
          transportType="By Bus"
          label1="Nearest Bus Station"
          value1="Rishikesh Bus Stand"
          distance1="15 Kms"
          // label2="International Airport"
          // value2="DEL - Indira Gandhi International Airport"
          // distance2="260 Kms"
        />

        <TransportTypeComp 
          img="by-train-icon.svg"
          transportType="By Train"
          label1="Nearest Railway Station"
          value1="New Rishikesh"
          distance1="17 Kms"
          // label2="International Airport"
          // value2="DEL - Indira Gandhi International Airport"
          // distance2="260 Kms"
        />
      </div>

    </div>
    <div className="background-rect-filled"></div>
    <div className="background-rect-border"></div>
  </section>)
}