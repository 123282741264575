export default [
  
    {
      id: 1,
      galleryImg: ["img1.png","img2.png","img3.png","img4.png","img5.png","img7.png","img8.png"],
    },
]



      // "img6.png", "img7.png", "img8.png"