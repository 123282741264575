import React from 'react';
import { FormContext } from  "./RyaCourseForm1";

export default function CoursePackageSelection(){
    const {formData, setFormData} = React.useContext(FormContext)

    const [selectedPackageObj, setSelectedPackageObj] = React.useState({
        id: "",
        person: "",
        room: "",
        price: 0
    })

    function handleChange(e){   
        setSelectedPackageObj(packages.find(item => {
                if(item.id === e.target.id){
                    return item
                }
            }
        ))    
    }
    React.useEffect(()=>{
        setFormData(prevData => {
            return {...prevData, selectedPackage: selectedPackageObj}
        })
    }, [selectedPackageObj])


    const packages = [
        {
            id: "a1",
            person: "1 Person",
            room: "Shared twin room",
            price: 72601
        },

        {
            id: "a2",
            person: "1 Person",
            room: "Private single room",
            price: 89631
        },

        {
            id: "a3",
            person: "2 Persons",
            room: "Private double bed room",
            price: 145082
        },
    
    ]
    const packageRadioEl = packages.map(item => {
        const {id, person, room, price } = item

        return(
            <div key={id}  
            // className='package-radio-comp'
            >
                <label 
                    key={id} 
                    htmlFor={id}
                    className={`input-label-box ${selectedPackageObj.room === room ? "selected-radio-label package-selected-box" : ""}`}
                > 
                    <div>
                        <input 
                            type="radio" 
                            name="package"
                            value={room}
                            id={id}
                            checked={selectedPackageObj.room === room}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='input-label-room-details'>
                        <div>
                            {person}
                            <p htmlFor={id} className='radio-room-details'>{room}</p>
                        </div>
                        <div className='radio-price-box'>
                            <p className={`radio-price-value ${selectedPackageObj.room === room ? "selected-price" : ""}`}>₹{price}</p>
                            <p className='label-total-price'>Total price</p>  
                        </div>
                    </div>
                </label>
            </div>   
        )
    })

    return(
          <div className='package-options-area'>
            <h5>Select Package</h5>
                {packageRadioEl}
            </div> 
    )
}
