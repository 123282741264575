import React from 'react';
import TopNavigation from './TopNavigation';
import SecondaryNav from "./SecondaryNav"
import {isMobile} from 'react-device-detect';
// import {BrowserView, MobileView} from 'react-device-detect';

import { SiteContext } from '../App';




export default function Header() {
  const { headerType } = React.useContext(SiteContext)

      if(isMobile && headerType === "Top Navigation"){   
        return <TopNavigation />
      } else if (isMobile && headerType === "Secondary Navigation") {
        return 
      } else if (!isMobile){
        return <TopNavigation />
      }

}


// export default function Header() {
//   const { headerType } = React.useContext(SiteContext)
//   return  (
//     <>
//        <TopNavigation />
//     </>
//   )
// }


