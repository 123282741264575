import React from "react"
import PrimaryButtonColor  from "../components/PrimaryButtonColor.js";


export default function PriceBottomBar() {
  return <div className="price-bottom-bar">
  <div className="container bottom-bar-content-area">  
   <div className="bottom-bar-duration-box">
      <p className="select-duration-label">Select duration</p>
      <div className="radio-input-area">
       
        <label for="duration1">
          <input type="radio" id="duration1" name="duration" value="HTML"/>
          3 Days
        </label>

        <label for="duration2">
          <input type="radio" id="duration2" name="duration" value="HTML"/>          
          7 Days
        </label>
        
        <label for="duration3">
          <input type="radio" id="duration3" name="duration" value="HTML"/>
          10 Days
        </label>
      </div>
    </div>

    <div className="bottom-bar-price-box">
      <div>
        <span><p className="rupeesymbol">₹</p></span>
        <span><p className="retreat-bottm-bar-price">12000</p></span>
        <span className="person-text">/person</span>
      </div>
      <div>
        <label for="c1" className="bottom-bar-checkbox-box">
          <input className="bottom-bar-checkbox" type="checkbox" name="c1" id="c1"/>
          Twin Sharing
        </label>
      </div>
    </div>
    <div>
      <PrimaryButtonColor 
        buttonText="Send Query Now"
        />
    </div>
  </div>

  </div>}

{/*      
      <input type="radio" id="html" name="fav_language" value="HTML">
      <label for="duration1">3 Days</label>
<input type="radio" id="html" name="fav_language" value="HTML">
      <label for="duration2">7 Days</label><br>
      <input type="radio" id="html" name="fav_language" value="HTML">
      <label for="duration3">10 Days</label>*/}