import React from 'react';
import ContentItems from '../../components/ContentItems';
import H2Comp from '../../components/H2Comp';
import { getDoc, collection, getDocs } from 'firebase/firestore';
import { courseAccomodationData, 
    courseImgGalleryData, 
    courseFoodData,
    courseIncluExcluData,
    courseOverviewData,
    courseProgramData,
    courseScheduleData,
 } from '../../data/YTT_RYA_data';

export default function CourseDetail(){

    const courseOverviewEl = courseOverviewData.map((item, idx)=>{
        return(
            <div key={`overview-${idx}`}>
                <ContentItems 
                    h4Text={item.h4Text}
                    pText={item.pText}
                    listItems={item.listItems}
                />
            </div>
        )
    })


    return(
        <>
            <br></br>
            <br></br>
            <div className="content-card">
                <H2Comp
                    h2Text="Overview"
                />
                {courseOverviewEl}
            </div>
        </>
    )
}