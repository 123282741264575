import React from "react";
import overview_section_data from "../../../data/retreat_data/atharva_retreat_data/atharva_overview_section_data.js";
import OverViewSection from "../OverViewSection.js";
import atharva_img_data from "../../../data/retreat_data/atharva_retreat_data/atharva_img_data.js";
import Carousal from "../../Carousal.js"; 






export default function RetreatHeader(props){
  
  const carousal = atharva_img_data.map( item => {
    return (<Carousal
        key={item.id} 
        imagePath= "atharva_retreat_images"
        galleryImg={item.galleryImg}
      />)
  })

  const overviewSection = overview_section_data.map(item => {
  return <OverViewSection 
      img = {item.img}
      h4Text = {item.h4Text}
      pText = {item.pText}
      label = {item.label}
    />
  })

  return  <div className="top-header container">
            
    <h1 className="retreat--h1">India Holistic Meditation and Yoga Retreat</h1>
    {/* {carousal} */}
    {overviewSection}
  </div>
}