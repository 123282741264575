import React from "react"
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";

import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';

import Courses from "./pages/Courses"
import CourseDetail from "./pages/VardanCourses/CourseDetail";

import PanchkarmaPage from './pages/PanchkarmaPage';
// import RetreatPage from './pages/RetreatPage';
import AtharvaRetreatPage from './pages/AtharvaRetreatPage';
import RyaCoursePage from './pages/Course/RyaCoursePage';
import RyaCoursePaymentPage from "./pages/Course/RyaCoursePaymentPage"
import CourseBookingConfirmation from "./pages/Course/CourseBookingConfirmation"

export default class SiteRouter extends React.Component {
  render() {
    return <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/panchkarma" element={<PanchkarmaPage />} />
        <Route path="/retreat" element={<AtharvaRetreatPage />} />

        <Route path="/courses" element={<Courses />} />
        <Route path="/courses/:id" element={<CourseDetail />} />
        
        <Route path="/course" element={<RyaCoursePage />} />
        <Route path="/course/contact-form" element={<RyaCoursePaymentPage />} />
        <Route path="/course/contact-form/confirmation" element={<CourseBookingConfirmation />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </BrowserRouter>
  }
}