export default [
  { 
    id: 1,
    h4Text: "Inclusions",
    pText: [],
    listItems: ["25 nights accommodation",
      "Rooms with hot water, attached bath, and toilet",
      "Unlimited free high-speed WiFi internet connection",
      "3 times daily nutritious vegetarian meals and teas",
      "Course books and course manual",
      "Cleansing material like Neti pot, rubber tube, etc.",
      "One Himalayas trip",
      "Usage of in-house library",
    ],
  
  },

  
  { 
    id: 2,
    h4Text: "Exclusions",
    pText: [],
    listItems: ["Flights",
      "Airport transfers",
      "Air-conditioner",
      "Room heater",
    ],
  },

]