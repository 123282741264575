'use client'
import { db } from '../firebase';
import React, {useState, useEffect} from 'react';




import { getDoc, collection, getDocs } from 'firebase/firestore';
import ContentItems from '../components/ContentItems';

import { Link, NavLink, Outlet } from 'react-router-dom';

async function getDataFromFireStore(){
    const quesrySnapshot = await getDocs(collection(db, "courses"))

    const data = []
    quesrySnapshot.forEach((doc)=>{
        data.push({id: doc.id, ...doc.data()})
    })
   return data
}


export default function Courses(){
    const [courseData, setCourseData] = useState([])

    useEffect(()=>{
        async function fetchData(){
            const data = await getDataFromFireStore()
            // console.log(data)
            setCourseData(data)
        }
        fetchData()
    },[])

    const courselist = courseData && courseData.map((course, idx)=>{
        console.log(course)
        const courseOverviewData = course.courseOverview
        console.log(courseOverviewData)

        const courseOverViewEl = course.courseOverview.map((item, idx)=>{

           return (
            <div key={`a-${idx}`}>
                <ContentItems 
                        h4Text={item.h4Text}
                        pText={item.pText}
                        listItems={item.listItems}
                    />
            </div>
            )
        })

        return (<div key={idx}>
                <Link to={`/courses/${course.id}`}>
                    <h3>{course.id}</h3>
                </Link>

                {courseOverViewEl}
            </div>
        )
    })


    return(
        <>
            <br></br>
            <br></br>
            <h3>courses list goes here</h3>
            <br></br>
            {courselist}

        </>
    )
}