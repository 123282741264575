import React from 'react';
import AtharvaRetreatHeader from "../components/retreat_components/atharva_retreat_components/AtharvaRetreatHeader.js";
import HighlightSection from "../components/retreat_components/atharva_retreat_components/HighlightSection.js";
import ProgramSection from "../components/retreat_components/atharva_retreat_components/ProgramSection.js";
import ScheduleSection from "../components/retreat_components/atharva_retreat_components/ScheduleSection.js";
import AccomodationSection from "../components/retreat_components/atharva_retreat_components/AccomodationSection.js";
import {BgImg2, BgImg3, BgImg4} from "../components/BgImgs.js";
import FoodSection from "../components/retreat_components/atharva_retreat_components/FoodSection.js";
import IncluExcluSection from "../components/retreat_components/atharva_retreat_components/IncluExcluSection.js";
import LocationSection from "../components/retreat_components/atharva_retreat_components/LocationSection.js";
import TtdSection from "../components/retreat_components/atharva_retreat_components/TtdSection.js";
import CPolicySection from "../components/retreat_components/atharva_retreat_components/CPolicySection.js";
import PriceBottomBar from "../components/PriceBottomBar.js";

import { motion } from "framer-motion"







class RetreatPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className="retreat-page">
      <div>
        <p className='breadcrumb'>Home / retreat</p>
      </div>
      <AtharvaRetreatHeader />
      <HighlightSection />
      <ProgramSection />
      <ScheduleSection />
      <AccomodationSection />
      <BgImg2 />
      <FoodSection />
      <IncluExcluSection />
      <BgImg3 />
      <LocationSection />
      <TtdSection />
      <CPolicySection />
      {window.innerWidth > 600 && <PriceBottomBar />}

    </div>
  }
}

export default RetreatPage;