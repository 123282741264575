import React from 'react';

// data import
import {panchkarmaPageData} from "../data/panchkarma_page_data"

import HeaderImage from "../components/HeaderImage"
import panchkarmaHeaderImg from "../assets/images/panchkarma_page_images/panchkarma-header-image.png"
import H2Comp from '../components/H2Comp';
import ContentItems from '../components/ContentItems';
import QueryForm from '../components/QueryForm';




export default function PanchkarmaPage () {

	const { about, consultation, treatments, additionalTreatments, relaxationPackages, relaxationPackagesList} = panchkarmaPageData

	const treatmentTypesList = treatments.treatmentTypes.map((item, idx) =>{
		return(
		<div key={idx}>
			<h4>{item.heading}</h4>
			<p>{item.pText}</p>
		</div>
			
		)
	}) 

	const additionaltreatmentTypesList = treatments.additionalTreatments.map((item, idx) =>{
		return(
		<div key={idx}>
			<h5>{item.heading}</h5>
			<p>{item.pText}</p>
		</div>
			
		)
	}) 


	return (
		<div className='panchkarma-page'>
			<HeaderImage
				imageClassName='top-header-image-panchkarma' 
				href={panchkarmaHeaderImg}
				heading1="Experience Authentic Ayurvedic Healing in Rishikesh"
			/>
			<div className='main-container container'>
				<div className='course-content-container'>
					<div className='content-card'>
						<H2Comp
							h2Text="Overview"
						/>
						<br></br>
						<h4>{about.heading}</h4>
						<p>{about.pText}</p>

						<h4>{consultation.heading}</h4>
						<p>{consultation.pText}</p>
					</div>


					<div className='content-card'>
						<H2Comp
							h2Text="Therapeutic Treatments"
						/>
						<br></br>
						{treatmentTypesList}
						<h4>Additional Therapiess</h4>
						{additionaltreatmentTypesList}
					</div>


					<div className='content-card'>
						<H2Comp
							h2Text="Relaxation Packages"
						/>
						<br></br>
						<p>{relaxationPackages.pText}</p>
						<RelaxationPackageList />
					</div>
				</div>
				<div className='form-container'>
					<QueryForm 
						saveDataTo="panchkarmaUserData"
					/>
				</div>
			</div>
		</div>
	)	
}

export function RelaxationPackageList(){
	const { relaxationPackagesList } = panchkarmaPageData
	const listItem = relaxationPackagesList.map((item, idx) => {
		return (
			<li key={`listItem-${idx}`}>
				<h4>{item.heading}</h4>
				<p>{item.pText}</p>
			</li>
		)
	  })
	
	  return (<ul>{listItem}</ul>)
}
