export default [
  { id: 1,
    time: "5:30",
    activity: "Wake up",},
    
  { id: 2,
    time: "6:00 - 7:00",
    activity: "Pranayama and Shatkarma",},
    

  { id: 3,
      time: "7:00 - 8:30",
      activity: "Traditional Hatha Yoga",
  },
  
  {
    id: 4,
    time: "8:30 - 9:30",
    activity: "Breakfast",
  },

  {
    id: 5,
    time: "10:00 - 13:00",
    activity: "Free Time or excursions",
  },
  
  {
    id: 6,
    time: "13:00 - 14:00",
    activity: "Lunch Break",
  },
  
  {
    id: 7,
    time: "16:00 - 17:30",
    activity: "Hatha Vinyasa Flow/Time Yoga",
  },
  
  {
    id: 8,
    time: "17:30 - 18:00",
    activity: "Tea Break",
  },
  
  {
    id: 9,
    time: "18:00 - 19:00",
    activity: "Meditation",
  },
  
  {
    id: 10,
    time: "19:00",
    activity: "Dinner Time",
  },
  
  {
    id: 11,
    time: "22:00",
    activity: "Bed Time",
  },
  

]
    
      // {id: 2,
      //       time: "7:00 - 8:30",
      //       activity: "Yoga and cleansing",}
    
    
    // {
    //   id: 3,
    //   time: "8:30 - 9:00",
    //   activity: "Fire ceremony with healing mantras",
    // },
    
    // {
    //   id: 4,
    //   time: "9:00 - 10:00",
    //   activity: "Breakfast",
    // },
    
    // {
    //   id: 5,
    //   time: "10:00 - 10:30",
    //   activity: "Silent walk",
    // },
    
    // {
    //   id: 6,
    //   time: "10:30 - 13:00",
    //   activity: "Break and consultations",
    // },
    
    // {
    //   id: 7,
    //   time: "13:00 - 14:00",
    //   activity: "Lunch",
    // },
    
    // {
    //   id: 8,
    //   time: "14:00 - 14:30",
    //   activity: "Digestive breathing",
    // },
    
    // {
    //   id: 9,
    //   time: "14:30 - 16:30",
    //   activity: "Self study",
    // },
    
    // {
    //   id: 10,
    //   time: "16:30 - 17:00",
    //   activity: "Evening tea",
    // },
    
    // {
    //   id: 11,
    //   time: "17:00 - 18:00",
    //   activity: "Yoga",
    // },
    
    // {
    //   id: 12,
    //   time: "18:15 - 19:15",
    //   activity: "Ayurveda spiritual session workshop",
    // },
    
    // {
    //   id: 13,
    //   time: "19:15 - 20:00",
    //   activity: "Dinner",
    // },
    
    // {
    //   id: 14,
    //   time: "20:30 - 21:00",
    //   activity: "Satsang",
    // },
    
    // {
    //   id: 15,
    //   time: "21:00",
    //   activity: "Lights off sleeping meditation",
    // },



    




//latest working  data:

// { 
//       id: 1,
//       time: "6:00 - 7:00",
//       activity: "Wake up",
//     },
      
//       { 
//       id: 2,
//       time: "7:00 - 8:30",
//       activity: "Yoga and cleansing",
//     },
    
//     {
//       id: 3,
//       time: "8:30 - 9:00",
//       activity: "Fire ceremony with healing mantras",
//     },
    
//     {
//       id: 4,
//       time: "9:00 - 10:00",
//       activity: "Breakfast",
//     },
    
//     {
//       id: 5,
//       time: "10:00 - 10:30",
//       activity: "Silent walk",
//     },
    
//     {
//       id: 6,
//       time: "10:30 - 13:00",
//       activity: "Break and consultations",
//     },
    
//     {
//       id: 7,
//       time: "13:00 - 14:00",
//       activity: "Lunch",
//     },
    
//     {
//       id: 8,
//       time: "14:00 - 14:30",
//       activity: "Digestive breathing",
//     },
    
//     {
//       id: 9,
//       time: "14:30 - 16:30",
//       activity: "Self study",
//     },
    
//     {
//       id: 10,
//       time: "16:30 - 17:00",
//       activity: "Evening tea",
//     },
    
//     {
//       id: 11,
//       time: "17:00 - 18:00",
//       activity: "Yoga",
//     },
    
//     {
//       id: 12,
//       time: "18:15 - 19:15",
//       activity: "Ayurveda spiritual session workshop",
//     },
    
//     {
//       id: 13,
//       time: "19:15 - 20:00",
//       activity: "Dinner",
//     },
    
//     {
//       id: 14,
//       time: "20:30 - 21:00",
//       activity: "Satsang",
//     },
    
//     {
//       id: 15,
//       time: "21:00",
//       activity: "Lights off sleeping meditation",
//     },




    // time:"6:00 - 7:00",
    // "7:00 - 8:30",
    // "8:30 - 9:00"
    // "9:00 - 10:00",
    // "10:00 - 10:30",
    // "10:30 - 13:00",
    // "13:00 - 14:00",
    // "14:00 - 14:30",
    // "14:30 - 16:30",
    // "16:30 - 17:00",
    // "17:00 - 18:00",
    // "18:15 - 19:15",
    // "19:15 - 20:00",
    // "20:30 - 21:00",
    // "21:00",
    // activity: "Wake up",
    // "Yoga and cleansing",
    // "Fire ceremony with healing mantras",
    // "Breakfast",
    // "Silent walk",
    // "Break and consultations",
    // "Lunch",
    // "Digestive breathing",
    // "Self study",
    // "Evening tea",
    // "Yoga",
    // "Ayurveda spiritual session workshop",
    // "Dinner",
    // "Satsang",
    // "Lights off sleeping meditation",




// { time: ["6:00 - 7:00",
//       "7:00 - 8:30",
//       "8:30 - 9:00",
//       "9:00 - 10:00",
//       "10:00 - 10:30",
//       "10:30 - 13:00",
//       "13:00 - 14:00",
//       "14:00 - 14:30",
//       "14:30 - 16:30",
//       "16:30 - 17:00",
//       "17:00 - 18:00",
//       "18:15 - 19:15",
//       "19:15 - 20:00",
//       "20:30 - 21:00",
//       "21:00"],
    
//       activity: ["Wake up",
//         "Yoga and cleansing",
//         "Fire ceremony with healing mantras",
//         "Breakfast",
//         "Silent walk",
//         "Break and consultations",
//         "Lunch",
//         "Digestive breathing",
//         "Self study",
//         "Evening tea",
//         "Yoga",
//         "Ayurveda spiritual session workshop",
//         "Dinner",
//         "Satsang",
//         "Lights off sleeping meditation"],
//     },





















  //       time:{a:"6:00 am - 7:00 am",
  //     b:"7:01 am - 8:30 am",
  //     c:"8:30 am - 9:00 am",
  //     d:"9:00 am - 10:00 am",
  //     e:"10:00 am - 10:30 am",
  //     f:"10:30am - 1:00 pm",
  //     g:"01:00 pm - 02:00 pm",
  //     h:"02:00 pm - 02:30 pm",
  //     i:"14:30 - 16:30",
  //     j:"9:00 - 10:00",
  //     k:"9:00 - 10:00",
  //     l:"9:00 - 10:00",
  //     m:"9:00 - 10:00",
  //     n:"9:00 - 10:00",
  //     o:"9:00 - 10:00am",
  //   },

  //   activity:{
  //     a:"Wake up",
  //     b:"Yoga and cleansing",
  //     c:"8:30am - 9:00am",
  //     d:"9:00 - 10:00",
  //     e:"10:00 - 10:30",
  //     f:"10:30am - 1:00pm",
  //     g:"9:00 - 10:00",
  //     h:"9:00 - 10:00",
  //     i:"9:00 - 10:00",
  //     j:"9:00 - 10:00",
  //     k:"9:00 - 10:00",
  //     l:"9:00 - 10:00",
  //     m:"9:00 - 10:00",
  //     n:"9:00 - 10:00",
  //     o:"9:00 - 10:00am",
  //   },
  // },

