import React from 'react';
import retreatCreativeImg from "../assets/images/home_page_images/retreat_creative.svg"
import mblRetreatCardBg from "../assets/images/home_page_images/mobile_retreat_bg.svg"
import { useNavigate } from 'react-router-dom';

export default function RetreatCard() {
  const navigate = useNavigate()
  return <div className= "hp-retreat-card container"> 
    <div className="retreat-box-area">
      <div className="retreat-text-content">
        <p className="retreat-duration">1 Week</p>
        <p className="retreat-heading">Ayurveda, Yoga & Spiritual Retreat</p>
        <p className="retreat-subhead">In the Lap of Nature near <strong>Rishikesh</strong></p>
        <button className="primary-btn-dark margin-32" onClick={()=>navigate("./retreat")}>Know More</button>
      </div>
      <img src={retreatCreativeImg}/>
     </div>
        <img className="mbl-retreat-card-bg" src= {mblRetreatCardBg}/>
  </div>

}