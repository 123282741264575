import React from 'react';
import CourseOverview from "./CourseOverview.js";
import CourseProgram from "./CourseProgram.js";
import CourseSchedule from "./CourseSchedule.js";
import CourseAccomodation from "./CourseAccomodation.js";
import CourseFood from "./CourseFood.js";
import CourseIncluExclu from "./CourseIncluExclu.js";
import CourseLocation from "./CourseLocation.js";
import QueryForm from "../QueryForm.js";
import CourseBookingForm from './CourseBookingForm.js';
import MobileBottomCTAs from './MobileBottomCTAs.js';


import { useNavigate, useLocation } from 'react-router-dom';


import RyaBookingCalendar from './RyaBookingCalendar.js';
import CoursePackageSelection from './CoursePackageSelection.js';
import H4Comp from '../H4Comp.js';
import { color } from 'framer-motion';
import LabelValueComponent from '../LabelValueComponent.js';

import { CourseContext } from '../../pages/Course/RyaCoursePage.js';
import RyaCourseForm1 from './RyaCourseForm1.js';

// export const FormContext = React.createContext()




export default function MainContainer(){

  // const [formData, setFormData] = React.useState({
  //   selectedDate: "",
  //   selectedPackage: "", 
  // })
 
  // const navigate = useNavigate()
  // const location = useLocation()
  // const { courseInfo } = React.useContext(CourseContext)

  return (<div className="main-container container">
    <div className = "course-content-container">
      <CourseOverview />
      <CourseProgram />
      <CourseSchedule />
      <CourseAccomodation />
      <CourseFood />
      <CourseIncluExclu />
      <CourseLocation />   
    </div>


    {window.innerWidth > "600" && <div className="form-container">
      <RyaCourseForm1 /> 
    </div>}
        {window.innerWidth < "600" && <MobileBottomCTAs />}
  </div>)
}
