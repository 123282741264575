export default [
  
  {
    id: 1,
    categoryName: "Course",
    categoryImg: "courses_img.png",
    categoryPath: "course"
  },

  {
    id: 2,
    categoryName: "Retreat",
    categoryImg: "retreat_img.png",
    categoryPath: "retreat"
  },

  {
    id: 3,
    categoryName: "Panchkarma",
    categoryImg: "panchkarma_img.png",
    categoryPath: "Panchkarma"
  },

  {
    id: 4,
    categoryName: "Ayurvedic Treatments",
    categoryImg: "ayurvedic_product_img.png",
    categoryPath: "ayurvedicTreatments"
  }

]