import React from "react";

import { IoClose } from "react-icons/io5";
import RyaBookingCalendar from "./RyaBookingCalendar";
import CoursePackageSelection from "./CoursePackageSelection";
import { useNavigate, useLocation } from "react-router-dom";

import { CourseContext } from "../../pages/Course/RyaCoursePage";
import { FormContext } from  "./RyaCourseForm1";
import RyaCourseForm1 from "./RyaCourseForm1";


// export const FormContext = React.createContext()

export default function MobileBottomCTAs(){
    const [open, setOpen] = React.useState(false)
    const location = useLocation()
  

    function openBookingForm(){
        setOpen(prevState => !prevState)
    }

    React.useEffect(()=>{
        if(open){document.body.style.overflow = 'hidden'}
        else{
            document.body.style.overflow = 'unset';
        }

        return () => { document.body.style.overflow = 'unset'}
    },[open])
    

    return(<>
        
        <div className={`${!open ? "mobile-bottom-cta-box" : "mobile-open-form"}`}>
            {open && 
                <div 
                    className="form-close-btn-box"
                >
                    <IoClose 
                        style={{width: "2rem", height: "2rem"}}
                        color="#C2DCE4" 
                        onClick={()=>{setOpen(prevState => !prevState)}} 
                    />
                </div>}

            { !open 
            ?
                <div className="mobile-bottom-button-area">
                    <button className="secondary-btn-color" onClick={()=>{}}>Send a Query</button>
                    <button className="primary-btn-color" onClick={openBookingForm}>Book Now</button>
                </div>
            :
                <RyaCourseForm1 />}
        </div>       
        <div style={{display: open && "block"}}id="form-overlay"></div>
        </>
    )
}