// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is 


const firebaseConfig = {
  apiKey: "AIzaSyCIal22oLmsgJehMrMh98noYmTlblQ2HeY",
  authDomain: "yogsidhi-site.firebaseapp.com",
  projectId: "yogsidhi-site",
  storageBucket: "yogsidhi-site.appspot.com",
  messagingSenderId: "477216250338",
  appId: "1:477216250338:web:018feb99675275e7fa81bb",
  measurementId: "G-KQJ4TNMTF9"
};

// Initialize Firebase


const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const db = getFirestore(app)

export {db}