
import React from "react"

// import logo from './logo.svg';
import './assets/css/common.scss';
import "./assets/css/commoncss_mobile.scss"
import "./assets/css/atharva_retreat.scss"
import "./assets/css/about_us.scss"
import "./assets/css/contactpage.scss"
import SiteRouter from './SiteRouter';
import Header from './components/Header';
import Footer from './components/Footer';

export const SiteContext = React.createContext()

function App() {
  const [headerType, setHeaderType] = React.useState("Top Navigation")
  const [secondNavText, setSecondNavText] = React.useState("")
  const [backLink, setBackLink] = React.useState("")

  return (
    <div className="body-elements">
      <SiteContext.Provider 
        value={{ 
          headerType, 
          setHeaderType, 
          secondNavText, 
          setSecondNavText,
          backLink,
          setBackLink
        }}
      >
        <Header />
        <SiteRouter/> 
        <Footer />
      </SiteContext.Provider>
    </div>
  );
}

export default App;
