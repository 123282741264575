import React from "react";

import { Link, Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "../../pages/Course/RyaCoursePaymentPage";
import CourseBookingConfirmation from "../../pages/Course/CourseBookingConfirmation";

export default function CourseBookingForm(){

    const {userAllData, setUserAllData} = React.useContext(UserContext)

    const navigate = useNavigate()

    // const{ completeData } = userAllData
   const totalBookingPrice = userAllData && userAllData.selectedPackage.price
    const duePayment = totalBookingPrice - totalBookingPrice * 30 / 100
    const advancePayment = totalBookingPrice - duePayment

    const [userData, setUserData] = React.useState({
        name: "",
        email: "",
        message: "",
    })

    React.useEffect(()=>{
        setUserAllData(prevData => {
            return {...prevData, userData: userData}
        })
    },[userData])
    
    function handleChange(event){
        // console.log(event)
        
        setUserData(prevFormData => ({
            ...prevFormData, [event.target.name]: event.target.value
        }))
    }

    function handleSubmit(event){
        event.preventDefault()
        console.log("form has submitted")
        const {name, email, message,} = userData
        const {courseInfo, selectedPackage, selectedDate} = userAllData;

        navigate("/course/contact-form/confirmation")
        const options = {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                name, email, message, courseInfo, selectedPackage, selectedDate
            })
        }
        const res = fetch("https://yogsidhi-site-default-rtdb.asia-southeast1.firebasedatabase.app/ryaUserData.json", options)    

    }

    return(
        <div className="course-booking-form-box">

            <div>
                <h5>Contact Details</h5>
            </div>
            
            <form onSubmit={handleSubmit}>
                <div>
                    <div className="email-box-area">
                    <label htmlFor="name">Name</label>
                    <br />
                    <input className="email-text"
                        type="text"
                        placeholder="Enter Your Full Name"
                        onChange={handleChange}
                        name="name"
                        value={userData.name}
                    />
                    </div>


                    <div className="email-box-area">
                        <label htmlFor="email">Email</label>
                        <br />
                        <input className="email-text"
                            type="email"
                            placeholder="Enter Email id"
                            onChange={handleChange}
                            name="email"
                            value={userData.email}
                        />
                    </div>


{/* 
                    <div className="email-box-area">
                        <label htmlFor="number">Phone Number(optional)</label>
                        <br />
                        <input className="email-text"
                            type="number"
                            min="10"
                            max="10"
                            placeholder="Enter Phone Number"
                            onChange={handleChange}
                            name="number"
                            value={userData.number}
                        />
                    </div> */}

                    <label htmlFor="message">Message</label>
                    <br />
                    <textarea 
                        className= "text-query-box" 
                        rows="5" 
                        type="text"
                        placeholder="Type your message here"
                        onChange={handleChange}
                        name="message"
                        value={userData.message}
                    />

                    <br/>

                    <button 
                        disabled = {!userData.name || !userData.email} 
                        className="primary-btn-color margin-32"
                    >
                        Send Booking Request 
                    </button>
                </div>
            </form>
        </div>
    )
}