import React from "react";
import CourseBookingForm from "../../components/rya_course_components/CourseBookingForm";
import RyaSelectedCoursePackage from "../../components/rya_course_components/RyaSelectedCoursePackage"
import SecondaryNav from "../../components/SecondaryNav";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { isMobile } from "react-device-detect";

import { SiteContext } from "../../App";

export const UserContext = React.createContext()

export default function CoursePayment(){
    const [userAllData, setUserAllData] = React.useState(null)
    // console.log(userAllData)
    const location = useLocation()

    const {
        headerType, 
        setHeaderType, 
        setSecondNavText,
        backLink, 
        setBackLink}
         = React.useContext(SiteContext)

    React.useEffect(()=>{
        setHeaderType("Secondary Navigation")
        setSecondNavText("Request to Book")
        setBackLink("./course")

        return () => {setHeaderType("Top Navigation")}
    },[])

    return(
        <UserContext.Provider value={ {userAllData, setUserAllData} } >
            <div>
                {isMobile && 
                    <SecondaryNav sendBackLink={"../course"}>
                        Request to Book
                    </SecondaryNav>
                }

                { !isMobile && 
                <div className="course-booking-page-title">
                    <Link to = {"../course"}>
                        <FontAwesomeIcon 
                            icon={faArrowLeft}
                            color="#24292B"    
                        />  
                    </Link>
                    <h3>Request to Book</h3>
                </div>
                }
                <div className="course-payment-page">
                    <CourseBookingForm /> 
                    <RyaSelectedCoursePackage />
                </div>
            </div>
        </UserContext.Provider>
     )
}